import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import dayjs from 'dayjs';
import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';
import GrupoAtendimentoAPI from 'src/APIs/AgendaAPI/GrupoAtendimento/GrupoAtendimentoAPI';
import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

type IContextProps = {
  agendamento: any;
  isAgendaCompletaOpened: boolean;
  isPesquisaProntuarioOpened: boolean;
  drawerConfirmarAgendamento: DisclosureType;
  dialogRemarcarAgendamento: DisclosureType;
  dialogProcedimentosSeriados: DisclosureType;
  dialogEditarPaciente: DisclosureType;
  dialogDetalhesAgendamento: DisclosureType;
  dialogGerenciarGrupos: DisclosureType;
  dialogImprimirEtiqueta: DisclosureType;
  agendaDiaItems: AtendimentoAgendamento[];
  registros: any[];
  loadingAgendamentosDia: boolean;
  selectedDate: Date;
  agendaView: AgendaViewTypes;
  endereco: any;
  refetchAgenda: () => void;
  refetchAgendaList: (novaData?: Date) => void;
  toggleViewAgenda: (openAgendaCompleta?: boolean) => void;
  togglePesquisaProntuario: () => void;
  handleConfirmarPresenca: (
    agendamento: any,
    onHideDialog?: () => void,
  ) => void;
  handleCloseConfirmarPresenca: () => void;
  setSelectedDate: (v: Date) => void;
  setAgendaView: (v: AgendaViewTypes) => void;
  editPacienteSemReload: (paciente: any) => void;
  setEndereco: (v: any) => void;
  reloadList: () => void;
  reload: number;
  getGrupo: (idGrupoAtendimento: number) => void;
  grupoAtendimento: GrupoAtendimentoGerenciar | undefined;
  selectAll: boolean;
  setSelectAll: any;
  selectedAgendamentos: any[];
  setSelectedAgendamentos: Dispatch<SetStateAction<any[]>>;
  isLoadingGrupoAtendimento: boolean;
  obrigaPresencaCuidado: boolean;
};

const AgendaContext = createContext({} as IContextProps);

export enum AgendaViewTypes {
  DIA = 'dia',
  DIAS = 'dias',
  SEMANA = 'semana',
}

export const AgendaProvider = ({ children }: { children: React.ReactNode }) => {
  const { visualizarAgenda } = useLocation()?.state || {};
  const { agenda, consultorios, user, query } = useAppSelector(
    (state: RootState) => state,
  );
  const [agendamento, setAgendamento] = useState<any>(null);
  const [agendaDiaItems, setAgendaDiaItems] = useState<
    AtendimentoAgendamento[]
  >([]);
  const [registros, setRegistros] = useState<any[]>([]);
  const [loadingAgendamentosDia, setLoadingAgendamentosDia] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(
    query?.state?.novaData ? query.state.novaData : new Date(),
  );
  const [agendaView, setAgendaView] = useState<AgendaViewTypes>(
    AgendaViewTypes.DIA,
  );
  const [reload, setReload] = useState(0);

  // Recurso tecnico (gambiarra) para nao dar refetch na agenda quando atualiza endereco do paciente. EMED-3836
  const [endereco, setEndereco] = useState<any>(null);
  const [grupoAtendimento, setGrupoAtendimento] =
    useState<GrupoAtendimentoGerenciar>();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedAgendamentos, setSelectedAgendamentos] = useState<any[]>([]);
  const [isLoadingGrupoAtendimento, setIsLoadingGrupoAtendimento] =
    useState(false);
  const [obrigaPresencaCuidado, setObrigaPresencaCuidado] =
    useState<boolean>(true);

  const dispatch = useDispatch();

  const dialogImprimirEtiqueta = useDisclosure({ opened: false });
  const dialogEditarPaciente = useDisclosure({ opened: false });
  const dialogDetalhesAgendamento = useDisclosure({ opened: false });
  const dialogGerenciarGrupos = useDisclosure({ opened: false });
  const dialogProcedimentosSeriados = useDisclosure({ opened: false });
  const dialogRemarcarAgendamento = useDisclosure({ opened: false });
  const drawerConfirmarAgendamento = useDisclosure({ opened: false });
  const pesquisaProntuarioDisclosure = useDisclosure({ opened: false });
  const agendaCompletaDisclosure = useDisclosure({
    opened:
      user?.tipoUsuario !== 'PROFISSIONAL_SAUDE' ||
      visualizarAgenda === 'AGENDA_COMPLETA',
  });

  const getGrupo = useCallback(async (idGrupoAtendimento: number) => {
    if (!idGrupoAtendimento) return;
    try {
      setIsLoadingGrupoAtendimento(true);
      const response = await GrupoAtendimentoAPI.loadGrupoAtendimentoDetalhe(
        idGrupoAtendimento,
      );

      setGrupoAtendimento(response);
    } catch {
    } finally {
      setIsLoadingGrupoAtendimento(false);
    }
  }, []);

  const reloadList = useCallback(() => {
    setReload(prev => prev + 1);
  }, []);

  //* Agenda
  const isAgendaCompletaOpened = agendaCompletaDisclosure.isOpen;
  const isPesquisaProntuarioOpened = pesquisaProntuarioDisclosure.isOpen;

  const toggleViewAgenda = (openAgendaCompleta?: boolean) => {
    const { isOpen, close, open } = agendaCompletaDisclosure;

    if (openAgendaCompleta !== undefined) {
      refetchAgendaList(selectedDate);
      openAgendaCompleta ? open() : close();
    } else {
      refetchAgendaList(selectedDate);
      isOpen ? close() : open();
    }
  };

  const togglePesquisaProntuario = useCallback(() => {
    const { isOpen, close, open } = pesquisaProntuarioDisclosure;
    return isOpen ? close() : open();
  }, [pesquisaProntuarioDisclosure]);

  const loadAgendamentos = useCallback(
    async (profissionalAtivoId, consultorioAtivoId) => {
      setLoadingAgendamentosDia(true);

      let selectedDateFim: any;

      if (agendaView === AgendaViewTypes.DIA)
        selectedDateFim = dayjs(selectedDate).format('YYYYMMDD');
      if (agendaView === AgendaViewTypes.DIAS)
        selectedDateFim = dayjs(selectedDate).add(2, 'day').format('YYYYMMDD');
      if (agendaView === AgendaViewTypes.SEMANA)
        selectedDateFim = dayjs(selectedDate).add(6, 'day').format('YYYYMMDD');

      try {
        if (!profissionalAtivoId || !consultorioAtivoId) return;

        const response = await AgendamentosAPI.loadAgendamentosDia(
          profissionalAtivoId,
          consultorioAtivoId,
          dayjs(selectedDate).format('YYYYMMDD') || '',
          selectedDateFim || '',
        );
        if (response && !('status' in response)) {
          setAgendaDiaItems(response[0]?.atendimentos || []);
        }
        if (agendaView !== AgendaViewTypes.DIA) {
          setRegistros(response);
        }
      } catch (error) {
        console.error('Erro ao carregar agendamentos do dia', error);
        setAgendaDiaItems([]);
      } finally {
        setLoadingAgendamentosDia(false);
      }
    },
    [agendaView, selectedDate],
  );

  const refetchAgenda = () => {
    dispatch(setInvalidateQuery({ invalidateAgenda: true }));
    loadAgendamentos(agenda?.profissionalAtivo?.id, consultorios?.ativo?.id);
  };

  const refetchAgendaList = (novaData?: Date) => {
    dispatch(
      setInvalidateQuery({
        invalidateAgendaList: true,
        ...(novaData && { state: { novaData } }),
      }),
    );
    if (novaData) {
      setSelectedDate(novaData);
    } else {
      loadAgendamentos(agenda?.profissionalAtivo?.id, consultorios.ativo.id);
    }
  };

  //* Confirmar presença
  const handleConfirmarPresenca = (
    agendamento: any,
    onHideDialog?: () => void,
  ) => {
    setAgendamento(agendamento);
    drawerConfirmarAgendamento.open({
      state: {
        onCloseExternalDialog: onHideDialog,
      },
    });
  };

  const handleCloseConfirmarPresenca = () => {
    setAgendamento(null);
    drawerConfirmarAgendamento.close();
  };

  const editPacienteSemReload = (paciente: any) => {
    const newAgendaDiaItems = agendaDiaItems.map(item => {
      if (item.paciente?.id === paciente.id) {
        return { ...item, paciente };
      } else {
        return item;
      }
    });

    setAgendaDiaItems(newAgendaDiaItems);
  };

  useEffect(() => {
    if (agenda.profissionalAtivo?.id && consultorios.ativo?.id) {
      if (
        !isAgendaCompletaOpened &&
        user?.idUsuario !== agenda.profissionalAtivo?.id
      ) {
        return;
      }
      loadAgendamentos(agenda.profissionalAtivo.id, consultorios.ativo.id);
    }
  }, [
    agenda.profissionalAtivo?.id,
    consultorios.ativo?.id,
    loadAgendamentos,
    isAgendaCompletaOpened,
    user?.idUsuario,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const response = await ParametroAPI.loadParametrosByCodigo(
          {
            codigo: 'STATUS_PRESENCA_CUIDADO',
          },
          { throwError: true },
        );
        if (!response.valor) return;
        setObrigaPresencaCuidado(response.valor === 'S');
      } catch {}
    })();
  }, []);

  return (
    <AgendaContext.Provider
      value={{
        agendamento,
        drawerConfirmarAgendamento,
        dialogProcedimentosSeriados,
        handleConfirmarPresenca,
        handleCloseConfirmarPresenca,
        refetchAgenda,
        toggleViewAgenda,
        togglePesquisaProntuario,
        isAgendaCompletaOpened,
        isPesquisaProntuarioOpened,
        dialogRemarcarAgendamento,
        refetchAgendaList,
        dialogEditarPaciente,
        dialogDetalhesAgendamento,
        dialogGerenciarGrupos,
        dialogImprimirEtiqueta,
        agendaDiaItems,
        loadingAgendamentosDia,
        selectedDate,
        setSelectedDate,
        agendaView,
        setAgendaView,
        registros,
        editPacienteSemReload,
        endereco,
        setEndereco,
        reloadList,
        reload,
        getGrupo,
        grupoAtendimento,
        selectAll,
        setSelectAll,
        selectedAgendamentos,
        setSelectedAgendamentos,
        isLoadingGrupoAtendimento,
        obrigaPresencaCuidado,
      }}
    >
      {children}
    </AgendaContext.Provider>
  );
};

export const useAgenda = () => useContext(AgendaContext);
