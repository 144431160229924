/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { MultiSelect } from 'primereact/multiselect';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';
import { AssinaturaAPI } from 'src/APIs/AgendaAPI/AssinaturaAPI/AssinaturaAPI';
import AtendimentoAPI from 'src/APIs/AgendaAPI/Atendimento/AtendimentoAPI';
import { AtendimentoEncaminhamentoAPI } from 'src/APIs/ProntuarioAPI/AtendimentoEncaminhamentoAPI/AtendimentoEncaminhamentoAPI';
import { MemedAPI } from 'src/APIs/ProntuarioAPI/MemedAPI/MemedAPI';
import ProntuarioPersistenteAPI from 'src/APIs/ProntuarioAPI/ProntuarioPersistenteAPI/ProntuarioPersistenteAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import {
  setAtendimento,
  resetAtendimento,
} from 'src/core/redux/slices/atendimento/AtendimentoSlice';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { getLabelFuncionalidades, convertFieldKeyToCamelCase } from './utils';
import {
  cleanObject,
  snakeToCamel,
  toScreamingSnakeCase,
} from 'src/utils/utils';

import DataOcorrencia from '../../components/DataOcorrencia/DataOcorrencia';
import GestacaoCard from '../../components/GestacaoCard/GestacaoCard';
import { CardEncaminhamento } from './_components/CardEncaminhamento/CardEncaminhamento';
import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import InfoDialog from 'src/components/Dialog/InfoDialog';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { justificativaOcorrenciaRetroativaValidation } from '../../FichaPaciente/helpers';
import ProtocoloAtendimentoDialog from '../ProtocoloAtendimento/ProtocoloAtendimentoDialog';

import AleitamentoAlimentacao from './AleitamentoAlimentacao/AleitamentoAlimentacao';
import Anamnese from './Anamnese/Anamnese';
import Ciap from './Ciap/Ciap';
import Cid from './Cid/Cid';
import DecisaoTerapeutica from './DecisaoTerapeutica/DecisaoTerapeutica';
import Desfecho from './Desfecho/Desfecho';
import { EncaminhamentoContrarreferencia } from './EncaminhamentoContrarreferencia/EncaminhamentoContrarreferencia';
import ExameFisico from './ExameFisico/ExameFisico';
import { FolhaRostoProntuario } from './FolhaRosto/FolhaRosto';
import HistoricoFamiliar from './HistoricoFamiliar/HistoricoFamiliar';
import HistoricoPessoal from './HistoricoPessoal/HistoricoPessoal';
import Impressos from './Impressos/Impressos';
import PatologiaDetectada from './PatologiaDetectada/PatologiaDetectada';
import PedidoCuidado from './PedidoCuidado/PedidoCuidado';
import { Prescricao } from './Prescricao/Prescricao';
import { ProcedimentosSeriadosAtendimento } from './ProcedimentosSeriadosAtendimento/ProcedimentosSeriadosAtendimento';
import Prognostico from './Prognostico/Prognostico';
import ReceitaHistorico from './Receita/ReceitaHistorico/ReceitaHistorico';
import MenuConfirmarConsulta from './TipoConsulta/MenuConfirmarConsulta';

import './IniciarAtendimento.scss';

interface IniciarAtendimentoProps {
  usuario: any;
  disabled: boolean;
  carregandoDados: boolean;
  idPaciente: number | null;
}

function IniciarAtendimento({
  usuario,
  disabled,
  carregandoDados,
  idPaciente,
}: IniciarAtendimentoProps) {
  const dispatch = useAppDispatch();

  const {
    query,
    user,
    agenda: { profissionalAtivo },
  } = useAppSelector((state: RootState) => state);
  const {
    atendimentoStatus,
    iniciarAtendimento,
    setCamposPEP,
    prontuarioPersistente,
    setProntuarioPersistente,
    guiasExecucoes,
    setPaciente,
    campos,
    getCampos,
    checkRequiredJustificativa,
    infoProtocoloDialog,
    protocoloDialog,
    protocolosAtendimento,
  } = useAtendimento();

  const navigate = useNavigate();
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const validationSchema = Yup.object().shape({
    dataOcorrencia: Yup.date().required('Informe a data da ocorrencia'),

    justificativaOcorrenciaRetroativa:
      justificativaOcorrenciaRetroativaValidation(null),
  });

  const validationTriagem = Yup.object().shape({});

  const { invalidateMemed } = query;

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(
      atendimentoStatus === 'ATENDENDO' ? validationSchema : validationTriagem,
    ),
  });

  const [selectedSubjetivos, setSelectedSubjetivos] = useState<any[]>([]);
  const [selectedObjetivos, setSelectedObjetivos] = useState<any[]>([]);
  const [selectedAvaliacao, setSelectedAvaliacao] = useState<any[]>([]);
  const [selectedPlano, setSelectedPlano] = useState<any[]>([]);

  const [prontuario, setProntuario] = useState<any>({});
  const [loadingProntuario, setLoadingProntuario] = useState<boolean>(false);
  const [, setIdPaciente] = useState(0);
  const [exibirFolhaRosto, setExibirFolhaRosto] = useState(false);

  const [encaminhamento, setEncaminhamento] = useState<any>([]);

  const [avisoIniciarAtendimentoVisivel, setAvisoIniciarAtendimentoVisivel] =
    useState<boolean>(true);
  const [confirmarConsultaVisible, setConfirmarConsultaVisible] =
    useState(false);

  const [
    quantidadeProntuariosNaoAssinados,
    setQuantidadeProntuariosNaoAssinados,
  ] = useState(0);

  const ndnAnamnese = useRef<any>(null);
  const ndnAleitamentoAlimentacao = useRef<any>(null);
  const ndnHistoricoPessoal = useRef<any>(null);
  const ndnHistoricoFamiliar = useRef<any>(null);
  const ndnExameFisico = useRef<any>(null);
  const ndnPatologiaDetectada = useRef<any>(null);
  const ndnDecisaoTerapeutica = useRef<any>(null);
  const ndnPrognostico = useRef<any>(null);

  useEffect(() => {
    ProfissionalAPI.getProfissional(profissionalAtivo.id, '').then(data =>
      setExibirFolhaRosto(
        !!data?.profissionalSaude?.exibirFolhaRostoProntuario,
      ),
    );
  }, [profissionalAtivo.id]);

  const folhaRostoDisclosure = useDisclosure({ opened: exibirFolhaRosto });
  const updateCamposProntuario = (novosCampos: any) => {
    setCamposPEP((prevState: any) => ({
      ...prevState,
      ...novosCampos,
    }));

    const fieldWithKeyCamelCase = convertFieldKeyToCamelCase(novosCampos);
    if (fieldWithKeyCamelCase)
      setProntuario((prevState: any) => ({
        ...prevState,
        ...fieldWithKeyCamelCase,
      }));
  };

  const updateProntuarioPersistente = (newValues: any) => {
    setProntuarioPersistente((prevProntuarioPersistente: any) => ({
      ...prevProntuarioPersistente,
      ...newValues,
    }));
  };

  const getProntuario = useCallback(async () => {
    if (!idAtendimento || !campos) return;
    setLoadingProntuario(true);
    try {
      const response = await UtilsAPI.getProntuario(Number(idAtendimento), {
        throwError: true,
      });
      setProntuario(response);
      setIdPaciente(response.atendimento?.paciente?.id);

      const updateCamposBasedOnProntuario = (
        campos: any[],
        prontuario: any,
      ) => {
        // Iterate through each key-value pair in prontuario
        Object.entries(prontuario).forEach(([key, value]) => {
          const keyInScreamingSnakeCase = toScreamingSnakeCase(key);

          // Check if the converted key matches any funcionalidade in campos
          const matchingCampo = campos.find(
            campo => campo.funcionalidade === keyInScreamingSnakeCase,
          );

          if (matchingCampo) {
            updateCamposProntuario({ [keyInScreamingSnakeCase]: value });
          }
        });
      };
      updateCamposBasedOnProntuario(campos, cleanObject(response));

      const memed = await MemedAPI.getPrepararReceitaPaciente({
        idPaciente: response.atendimento?.paciente?.id || idPaciente,
      });

      dispatch(
        setAtendimento({
          ...response.atendimento,
          particular: response?.particular || false,
          retorno: response?.retorno || false,
          tempoGastoAtendimento: response.tempoGastoAtendimento,
          memed,
        }),
      );
    } catch (error) {
      setProntuario({});
    } finally {
      setLoadingProntuario(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, idAtendimento, idPaciente, campos]);

  useEffect(() => {
    if (!idPaciente) return;
    const getProntuarioPersistente = () => {
      setLoadingProntuario(true);

      ProntuarioPersistenteAPI.getProntuarioPersistente({
        idPaciente,
      })
        .then(res => {
          setProntuarioPersistente(res);
        })
        .finally(() => {
          setLoadingProntuario(false);
        });
    };
    getProntuarioPersistente();
  }, [idPaciente, setProntuarioPersistente]);

  useEffect(() => {
    if (
      atendimentoStatus.includes('ATENDENDO') ||
      atendimentoStatus.includes('ATENDIDO')
    )
      getProntuario();
  }, [atendimentoStatus, getProntuario]);

  useEffect(() => {
    return () => {
      dispatch(resetAtendimento());
    };
  }, [dispatch]);

  useEffect(() => {
    const getQuantidadeProntuariosNaoAssinados = async () => {
      const response = await AssinaturaAPI.getProntuariosNaoAssinados({
        idMedico: profissionalAtivo.id,
      });
      setQuantidadeProntuariosNaoAssinados(response?.data?.totalCount);
    };
    getQuantidadeProntuariosNaoAssinados();
  }, [profissionalAtivo.id]);

  useEffectSkipFirst(() => {
    if (invalidateMemed) {
      MemedAPI.getPrepararReceitaPaciente({
        idPaciente: Number(idPaciente),
      })
        .then(data => dispatch(setAtendimento({ memed: data })))
        .finally(() => dispatch(resetInvalidateQuery()));
    }
  }, [invalidateMemed]);

  useEffect(() => {
    if (
      atendimentoStatus === 'ATENDENDO' ||
      atendimentoStatus === 'ATENDENDO_TRIAGEM'
    ) {
      getCampos();
    }
  }, [atendimentoStatus, getCampos]);

  useEffect(() => {
    const fixosSubjetivos: any[] = [];
    const fixosObjetivos: any[] = [];
    const fixosAvaliacao: any[] = [];
    const fixosPlano: any[] = [];

    if (Array.isArray(campos)) {
      campos.map(campo => {
        if (
          (campo.exibicaoCamposModelo === 'FIXO' &&
            campo.aba === 'Subjetivo') ||
          (campo.aba === 'Subjetivo' && campo.preenchido === true)
        ) {
          fixosSubjetivos.push(campo.funcionalidade);
        }
        if (
          (campo.exibicaoCamposModelo === 'FIXO' && campo.aba === 'Objetivo') ||
          (campo.aba === 'Objetivo' && campo.preenchido === true)
        ) {
          fixosObjetivos.push(campo.funcionalidade);
        }
        if (
          (campo.exibicaoCamposModelo === 'FIXO' &&
            campo.aba === 'Avaliação') ||
          (campo.aba === 'Avaliação' && campo.preenchido === true)
        ) {
          fixosAvaliacao.push(campo.funcionalidade);
        }
        if (
          (campo.exibicaoCamposModelo === 'FIXO' && campo.aba === 'Plano') ||
          (campo.aba === 'Plano' && campo.preenchido === true)
        ) {
          fixosPlano.push(campo.funcionalidade);
        }
      });
    }

    setSelectedSubjetivos(fixosSubjetivos);
    setSelectedObjetivos(fixosObjetivos);
    setSelectedAvaliacao(fixosAvaliacao);
    setSelectedPlano(fixosPlano);
  }, [campos]);

  function onHideAvisoIniciarAtendimento() {
    setAvisoIniciarAtendimentoVisivel(false);
  }

  const handleIniciarAtendimento = () => {
    localStorage.removeItem('definicoes');
    iniciarAtendimento(Number(idAtendimento), handleChangeDataOcorrencia);
  };

  const [buttonLabel, showIniciarAtendimentoBtn] = useMemo(() => {
    if (atendimentoStatus === 'PRESCRICAO') return ['Iniciar prescrição', true];

    if (atendimentoStatus === 'TRIAGEM') return ['Iniciar triagem', true];

    if (atendimentoStatus === 'CONTINUAR')
      return ['Continuar atendimento', true];

    if (atendimentoStatus.includes('ATENDIDO'))
      return ['Complementar prontuário', true];

    if (!atendimentoStatus.includes('ATENDENDO'))
      return ['Iniciar Atendimento', true];

    return ['', false];
  }, [atendimentoStatus]);

  const buttonType = useMemo(() => {
    if (atendimentoStatus.includes('ATENDIDO')) return 'tonal';
    if (atendimentoStatus !== 'ATENDENDO') return 'pill';

    return 'danger';
  }, [atendimentoStatus]);

  const opcoesOrdenacaoMenuSubjetivo = useMemo(() => {
    const options: any[] = [];

    if (Array.isArray(campos)) {
      campos?.forEach(campo => {
        if (
          campo.aba === 'Subjetivo' &&
          campo.exibicaoCamposModelo !== 'OCULTO'
        ) {
          options.push({
            ...campo,
            label: getLabelFuncionalidades[campo.funcionalidade],
          });
        }
      });
    }

    return options;
  }, [campos]);

  const opcoesOrdenacaoMenuObjetivo = useMemo(() => {
    const options: any[] = [];

    if (Array.isArray(campos)) {
      campos?.forEach(campo => {
        if (
          campo.aba === 'Objetivo' &&
          campo.exibicaoCamposModelo !== 'OCULTO'
        ) {
          options.push({
            ...campo,
            label: getLabelFuncionalidades[campo.funcionalidade],
          });
        }
      });
    }

    return options;
  }, [campos]);

  const opcoesOrdenacaoMenuAvaliacao = useMemo(() => {
    const options: any[] = [];

    if (Array.isArray(campos)) {
      campos?.forEach(campo => {
        if (
          campo.aba === 'Avaliação' &&
          campo.exibicaoCamposModelo !== 'OCULTO' &&
          campo.funcionalidade !== 'AVALIACAO_RISCO'
        ) {
          options.push({
            ...campo,
            label: getLabelFuncionalidades[campo.funcionalidade],
          });
        }
      });
    }

    return options;
  }, [campos]);

  const opcoesOrdenacaoMenuPlano = useMemo(() => {
    let options: any[] = [];

    if (Array.isArray(campos)) {
      campos?.forEach(campo => {
        if (campo.aba === 'Plano' && campo.exibicaoCamposModelo !== 'OCULTO') {
          options.push({
            ...campo,
            label: getLabelFuncionalidades[campo.funcionalidade],
          });
        }
      });
    }

    //* Removendo receita_oculos provisóriamente (em definição)
    options = options.filter(
      option => option.funcionalidade !== 'RECEITA_OCULOS',
    );

    return options;
  }, [campos]);

  const setCurrentRef = useCallback(() => {
    selectedSubjetivos.includes('ANAMNESE') ? ndnAnamnese.current() : null;
    selectedSubjetivos.includes('ALEITAMENTO_ALIMENTACAO')
      ? ndnAleitamentoAlimentacao.current()
      : null;
    selectedSubjetivos.includes('HISTORICO_PESSOAL')
      ? ndnHistoricoPessoal.current()
      : null;
    selectedSubjetivos.includes('HISTORICO_FAMILIAR')
      ? ndnHistoricoFamiliar.current()
      : null;
    selectedObjetivos.includes('EXAME_FISICO')
      ? ndnExameFisico.current()
      : null;
    selectedAvaliacao.includes('PROGNOSTICO') ? ndnPrognostico.current() : null;
    selectedAvaliacao.includes('PATOLOGIA_DETECTADA')
      ? ndnPatologiaDetectada.current()
      : null;
    selectedPlano.includes('DECISAO_TERAPEUTICA')
      ? ndnDecisaoTerapeutica.current()
      : null;
  }, [selectedSubjetivos, selectedObjetivos, selectedAvaliacao, selectedPlano]);

  useEffect(() => {
    if (idAtendimento)
      AtendimentoEncaminhamentoAPI.loadEncaminhamentos(idAtendimento, {
        throwError: true,
        errorHideToast: true,
      })
        .then(item => [
          setEncaminhamento(
            item.filter(
              item => item.tipo === 'Encaminhamento' && !item.respondido,
            ),
          ),
        ])
        .catch(() => {});
  }, [idAtendimento]);

  const hasEncaminhamento =
    encaminhamento.length > 0 &&
    encaminhamento[0]?.idAtendimento !== idAtendimento;

  const watchDataOcorrencia = watch('dataOcorrencia');
  const watchJustificativaOcorrencia = watch('justificativaOcorrencia');

  const handleOcorrenciaRetroativa = useCallback(async () => {
    if (!idAtendimento) return;
    const data = {
      dataOcorrencia: watchDataOcorrencia,
      justificativaOcorrencia: getValues('justificativaOcorrencia'),
    };
    const response = await AtendimentoAPI.blurOcorrenciaRetroativa(
      data,
      Number(idAtendimento),
    );

    setPaciente(response?.data?.paciente);

    return;
  }, [getValues, idAtendimento, setPaciente, watchDataOcorrencia]);

  useEffect(() => {
    const data = {
      dataOcorrencia: watchDataOcorrencia,
      justificativaOcorrencia: watchJustificativaOcorrencia,
    };
    checkRequiredJustificativa(data);
  }, [
    checkRequiredJustificativa,
    watchDataOcorrencia,
    watchJustificativaOcorrencia,
  ]);

  const handleChangeDataOcorrencia = async (
    atendimentoId: number | string,
    dataOcorrencia: Date | Date[] | undefined,
  ) => {
    if (!!dataOcorrencia && !Array.isArray(dataOcorrencia) && !!atendimentoId) {
      const data = {
        dataOcorrencia,
        justificativaOcorrencia: getValues('justificativaOcorrencia'),
      };

      const response = await AtendimentoAPI.blurOcorrenciaRetroativa(
        data,
        Number(atendimentoId),
      );
      setPaciente(response?.data?.paciente);
    }
  };

  return (
    <div className="p-col-12 p-p-0">
      <div id="iniciar-atendimento-container">
        <Skeleton
          loading={carregandoDados || loadingProntuario}
          height="140px"
          borderRadius="8px"
        >
          <React.Fragment>
            {hasEncaminhamento && showIniciarAtendimentoBtn && (
              <CardEncaminhamento item={encaminhamento[0]} />
            )}

            <ElevatedCard className="card-iniciar-atendimento" noMargin>
              <div className="p-p-1">
                {atendimentoStatus === 'ATENDENDO_TRIAGEM' && campos.length && (
                  <Ciap
                    usuario={usuario}
                    campos={campos}
                    updateCamposProntuario={updateCamposProntuario}
                    atendimentoStatus={atendimentoStatus}
                  />
                )}

                {atendimentoStatus === 'ATENDENDO' && (
                  <>
                    <div className="p-d-flex-column">
                      <div className="p-d-flex p-jc-between">
                        <SimpleText
                          className="p-ml-2"
                          fontSize={FONT_SIZE.MD}
                          fontColor={FONT_COLOR.COLOR_16}
                          medium
                        >
                          Novo atendimento
                        </SimpleText>
                        <Button
                          btnType="warn"
                          className="ndn-button p-px-4"
                          onClick={() => {
                            setCurrentRef();
                          }}
                        >
                          NDN
                        </Button>
                      </div>
                      <form>
                        <DataOcorrencia
                          control={control}
                          defaultValue={new Date()}
                          nameDataOcorrencia="dataOcorrencia"
                          nameJustificativaRetroativa="justificativaOcorrencia"
                          onBlurJustificativa={() =>
                            handleOcorrenciaRetroativa()
                          }
                          onChangeDataOcorrencia={dataOcorrencia =>
                            handleChangeDataOcorrencia(
                              idAtendimento,
                              dataOcorrencia,
                            )
                          }
                        />
                      </form>
                    </div>
                    <div className="p-d-flex p-my-3 p-ai-center p-jc-between">
                      <div className="p-d-flex menu-prontuario p-gap-2">
                        <MultiSelect
                          className="aba-campo-prontuario"
                          optionLabel="label"
                          optionValue="funcionalidade"
                          value={selectedSubjetivos}
                          options={opcoesOrdenacaoMenuSubjetivo}
                          onChange={e => {
                            setSelectedSubjetivos(e.value);
                          }}
                          placeholder="Subjetivo"
                          fixedPlaceholder
                          panelHeaderTemplate
                          panelClassName="multiselect-prontuario"
                        />
                        <MultiSelect
                          className="aba-campo-prontuario"
                          optionLabel="label"
                          optionValue="funcionalidade"
                          value={selectedObjetivos}
                          options={opcoesOrdenacaoMenuObjetivo}
                          onChange={e => setSelectedObjetivos(e.value)}
                          placeholder="Objetivo"
                          fixedPlaceholder
                          panelHeaderTemplate
                          panelClassName="multiselect-prontuario"
                        />
                        <MultiSelect
                          className="aba-campo-prontuario"
                          optionLabel="label"
                          optionValue="funcionalidade"
                          value={selectedAvaliacao}
                          options={opcoesOrdenacaoMenuAvaliacao}
                          onChange={e => setSelectedAvaliacao(e.value)}
                          placeholder="Avaliação"
                          fixedPlaceholder
                          panelHeaderTemplate
                          panelClassName="multiselect-prontuario"
                        />
                        <MultiSelect
                          className="aba-campo-prontuario"
                          optionLabel="label"
                          optionValue="funcionalidade"
                          value={selectedPlano}
                          options={opcoesOrdenacaoMenuPlano}
                          onChange={e => setSelectedPlano(e.value)}
                          placeholder="Plano"
                          fixedPlaceholder
                          panelHeaderTemplate
                          panelClassName="multiselect-prontuario"
                        />

                        <Button
                          className="button-protocolo-atendimento"
                          btnType="gray"
                          onClick={() => {
                            protocoloDialog.open();
                          }}
                          disabled={!protocolosAtendimento.length}
                        >
                          <div className="p-d-flex p-ai-center p-jc-between p-flex-1">
                            <SimpleText
                              className="cursor-pointer"
                              fontColor="color_40"
                              medium
                            >
                              Protocolo de atendimento
                            </SimpleText>
                            <i
                              className="fas fa-info-circle"
                              onClick={event => {
                                event.stopPropagation();
                                if (protocolosAtendimento.length) {
                                  infoProtocoloDialog.open();
                                }
                              }}
                            />
                          </div>
                        </Button>
                      </div>
                      <div>
                        <Button icon="fas fa-ellipsis-h" btnType="gray" />
                      </div>
                    </div>

                    {folhaRostoDisclosure.isOpen && (
                      <FolhaRostoProntuario
                        {...folhaRostoDisclosure}
                        idPaciente={idPaciente}
                      />
                    )}

                    <GestacaoCard showOnlyGestacaoAtual />

                    {guiasExecucoes?.length > 0 && (
                      <ProcedimentosSeriadosAtendimento
                        guiasExecucoes={guiasExecucoes}
                      />
                    )}

                    {selectedSubjetivos.length > 0 && (
                      <div className="aba">
                        <SimpleText
                          className="p-ml-2 p-pt-2"
                          fontSize={FONT_SIZE.SM}
                          fontColor={FONT_COLOR.COLOR_16}
                          medium
                        >
                          Subjetivo
                        </SimpleText>
                        {selectedSubjetivos.includes('ANAMNESE') && (
                          <Anamnese
                            prontuario={prontuario}
                            campos={campos}
                            getCampos={getCampos}
                            updateCamposProntuario={updateCamposProntuario}
                            ndnAnamnese={ndnAnamnese}
                            selectedSubjetivos={selectedSubjetivos}
                          />
                        )}
                        {selectedSubjetivos.includes('HISTORICO_PESSOAL') && (
                          <HistoricoPessoal
                            prontuario={prontuario}
                            prontuarioPersistente={prontuarioPersistente}
                            campos={campos}
                            getCampos={getCampos}
                            updateCamposProntuario={updateCamposProntuario}
                            updateProntuarioPersistente={
                              updateProntuarioPersistente
                            }
                            selectedSubjetivos={selectedSubjetivos}
                            ndnHistoricoPessoal={ndnHistoricoPessoal}
                          />
                        )}
                        {selectedSubjetivos.includes('HISTORICO_FAMILIAR') && (
                          <HistoricoFamiliar
                            prontuario={prontuario}
                            prontuarioPersistente={prontuarioPersistente}
                            campos={campos}
                            getCampos={getCampos}
                            updateCamposProntuario={updateCamposProntuario}
                            updateProntuarioPersistente={
                              updateProntuarioPersistente
                            }
                            selectedSubjetivos={selectedSubjetivos}
                            ndnHistoricoFamiliar={ndnHistoricoFamiliar}
                          />
                        )}
                        {selectedSubjetivos.includes(
                          'ALEITAMENTO_ALIMENTACAO',
                        ) && (
                          <AleitamentoAlimentacao
                            prontuario={prontuario}
                            campos={campos}
                            getCampos={getCampos}
                            updateCamposProntuario={updateCamposProntuario}
                            selectedSubjetivos={selectedSubjetivos}
                            ndnAleitamentoAlimentacao={
                              ndnAleitamentoAlimentacao
                            }
                          />
                        )}
                      </div>
                    )}

                    {selectedObjetivos.length > 0 && (
                      <div className="aba">
                        <SimpleText
                          className="p-ml-2 p-pt-2"
                          fontSize={FONT_SIZE.SM}
                          fontColor={FONT_COLOR.COLOR_16}
                          medium
                        >
                          Objetivo
                        </SimpleText>
                        {selectedObjetivos.includes('EXAME_FISICO') && (
                          <ExameFisico
                            prontuario={prontuario}
                            campos={campos}
                            getCampos={getCampos}
                            updateCamposProntuario={updateCamposProntuario}
                            selectedObjetivos={selectedObjetivos}
                            ndnExameFisico={ndnExameFisico}
                          />
                        )}
                      </div>
                    )}

                    {selectedAvaliacao.length > 0 && (
                      <div className="aba">
                        <SimpleText
                          className="p-ml-2 p-pt-2"
                          fontSize={FONT_SIZE.SM}
                          fontColor={FONT_COLOR.COLOR_16}
                          medium
                        >
                          Avaliação
                        </SimpleText>
                        {selectedAvaliacao.includes('PROGNOSTICO') && (
                          <Prognostico
                            prontuario={prontuario}
                            campos={campos}
                            getCampos={getCampos}
                            updateCamposProntuario={updateCamposProntuario}
                            ndnPrognostico={ndnPrognostico}
                            selectedAvaliacao={selectedAvaliacao}
                          />
                        )}

                        {selectedAvaliacao.includes('PATOLOGIA_DETECTADA') && (
                          <PatologiaDetectada
                            prontuario={prontuario}
                            campos={campos}
                            getCampos={getCampos}
                            updateCamposProntuario={updateCamposProntuario}
                            ndnPatologiaDetectada={ndnPatologiaDetectada}
                            selectedAvaliacao={selectedAvaliacao}
                          />
                        )}
                        {selectedAvaliacao.includes('CIAP') && (
                          <Ciap
                            usuario={usuario}
                            campos={campos}
                            updateCamposProntuario={updateCamposProntuario}
                            atendimentoStatus={atendimentoStatus}
                          />
                        )}

                        {selectedAvaliacao.includes('CID') && (
                          <Cid
                            usuario={usuario}
                            campos={campos}
                            updateCamposProntuario={updateCamposProntuario}
                          />
                        )}
                      </div>
                    )}

                    {selectedPlano.length > 0 && (
                      <div className="aba">
                        <SimpleText
                          className="p-ml-2 p-pt-2"
                          fontSize={FONT_SIZE.SM}
                          fontColor={FONT_COLOR.COLOR_16}
                          medium
                        >
                          Plano
                        </SimpleText>
                        {selectedPlano.includes('PRESCRICAO') && (
                          <Prescricao
                            campos={campos}
                            reloadCampos={updateCamposProntuario}
                          />
                        )}
                        {selectedPlano.includes('DECISAO_TERAPEUTICA') && (
                          <DecisaoTerapeutica
                            prontuario={prontuario}
                            campos={campos}
                            getCampos={getCampos}
                            updateCamposProntuario={updateCamposProntuario}
                            selectedPlano={selectedPlano}
                            ndnDecisaoTerapeutica={ndnDecisaoTerapeutica}
                          />
                        )}

                        {selectedPlano.includes('RECEITA') && (
                          <ReceitaHistorico campos={campos} />
                        )}
                        {selectedPlano.includes(
                          'ENCAMINHAMENTO_CONTRARREFERENCIA',
                        ) && (
                          <EncaminhamentoContrarreferencia
                            reloadCampos={updateCamposProntuario}
                            campos={campos}
                          />
                        )}
                        {selectedPlano.includes('PEDIDO_CUIDADO') && (
                          <PedidoCuidado
                            campos={campos}
                            idPaciente={idPaciente}
                          />
                        )}
                        {selectedPlano.includes('DESFECHO_ATENDIMENTO') && (
                          <Desfecho
                            usuario={usuario}
                            campos={campos}
                            updateCamposProntuario={updateCamposProntuario}
                            desfechoEmpresa={prontuario?.desfechoEmpresa}
                          />
                        )}
                        {selectedPlano.includes('IMPRESSO') && (
                          <Impressos campos={campos} />
                        )}
                        {/* {selectedPlano.includes('RECEITA_OCULOS') && (
                          <ReceitaOculos campos={campos} />
                        )} */}
                      </div>
                    )}
                  </>
                )}

                {showIniciarAtendimentoBtn && (
                  <>
                    <div className="p-col-12 p-px-0 p-pt-0">
                      <Button
                        stretch
                        btnType={buttonType}
                        label={buttonLabel}
                        onClick={handleSubmit(handleIniciarAtendimento)}
                        disabled={disabled}
                        loading={isSubmitting}
                      />
                    </div>

                    {atendimentoStatus.includes('CONTINUAR') && (
                      <AlertBox
                        text="Continue o atendimento para adicionar informações na ficha do paciente."
                        className="p-mt-1"
                        visible
                      />
                    )}

                    {!disabled && atendimentoStatus.includes('PRESENTE') && (
                      <AlertBox
                        text="Inicie o atendimento para adicionar informações na ficha do paciente"
                        visible={avisoIniciarAtendimentoVisivel}
                        onHide={onHideAvisoIniciarAtendimento}
                        className="p-mt-1"
                      />
                    )}

                    {quantidadeProntuariosNaoAssinados > 0 && (
                      <div className="p-d-flex p-flex-column p-gap-2">
                        <AlertBox
                          text={`Há ${quantidadeProntuariosNaoAssinados} apontamentos sem assinatura.`}
                          className="p-mt-1"
                          type="DANGER"
                          visible
                        />

                        <Button
                          btnType="green-link"
                          className="p-col-4 p-lg-3"
                          label="Assinar prontuários"
                          onClick={() =>
                            navigate('/pacientes/assinatura-prontuarios')
                          }
                          // checkPermission="PRO_ASSINATURA_ELETRONICA"
                          style={{
                            justifyContent: 'start',
                            paddingLeft: '2px',
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </ElevatedCard>
          </React.Fragment>
        </Skeleton>

        {confirmarConsultaVisible && (
          <MenuConfirmarConsulta
            visible={confirmarConsultaVisible}
            onHide={() => setConfirmarConsultaVisible(false)}
            idAtendimento={idAtendimento}
          />
        )}
      </div>
      {infoProtocoloDialog.isOpen && (
        <InfoDialog
          header="Orientação de uso do protocolo de atendimento"
          onHide={() => infoProtocoloDialog.close()}
          visible
        >
          <>
            <SimpleText fontColor="color_40" fontSize="sm">
              Para utilizar a facilidade do autopreenchimento do prontuário de
              atendimento, um fluxo do protocolo de atendimento deve ser
              primeiramente cadastrado!
            </SimpleText>
            <SimpleText fontColor="color_40" fontSize="sm">
              Acesse a tela de{' '}
              <strong>cadastros de protocolo de atendimento.</strong>
            </SimpleText>
          </>
        </InfoDialog>
      )}

      {protocoloDialog.isOpen && (
        <ProtocoloAtendimentoDialog
          {...protocoloDialog}
          idAtendimento={idAtendimento}
          getProntuario={getProntuario}
        />
      )}
    </div>
  );
}

export default IniciarAtendimento;
