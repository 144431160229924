import { PacienteFamiliarPayload } from 'src/models/APIs/ProntuarioAPI/PacienteFamiliarAPI/PacienteFamiliarAPI';

import Http, { HttpParams } from 'src/core/http/Http';

import { IFamiliarPaciente, IFamiliarPayload } from './Familiar';

const basepath = '/familiar-paciente';

const loadPacienteFamiliares = async (
  idPaciente: string | number,
  params: {
    sortBy: string;
    sortDirection: string;
    page?: number;
    pageSize?: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IFamiliarPaciente[]>> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/paciente/${idPaciente}`,
    hideToast: true,
    params,
    throwError: true,
    ...options,
  });
};

const loadVinculosFamiliares = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/vinculo-familiar`,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

const createPacienteFamiliares = async (
  payload: IFamiliarPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basepath}`,
    data: payload,
    throwError: true,
    ...options,
  });
};

const editFamiliar = async (
  id: number,
  payload: PacienteFamiliarPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `${basepath}/${id}`,
    data: payload,
    throwError: true,
    ...options,
  });
};

const deleteFamiliar = async (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'pep',
    url: `${basepath}/${id}`,
    throwError: true,
    ...options,
  });
};

export const FamiliarAPI = {
  loadPacienteFamiliares,
  loadVinculosFamiliares,
  createPacienteFamiliares,
  editFamiliar,
  deleteFamiliar,
};
