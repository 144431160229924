import { useEffect, useState } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import RelatoriosAPI from 'src/APIs/AdminAPI/RelatoriosAPI/RelatoriosAPI';
import * as Yup from 'yup';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { handleOpenPDF, openBase64PDFInNewTab } from 'src/utils/files';
import { fetchParametro } from 'src/utils/utils';

import { DialogAssinarDigitalmente } from '../../Admin/AssinaturasProntuario/components/DialogAssinarDigitalmente';
import SearchPacientes from '../CentralPacientes/components/SearchPacientes/SearchPacientes';
import AlertBox from 'src/components/AlertBox/AlertBox';
import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Toast from 'src/components/Basics/Toast/Toast';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';

import './ExportacaoProntuario.scss';

type FormValues = {
  prontuarioCompleto: boolean;
  responsavelLegal: boolean;
  justificativa: string;
  dataInicio: Date;
  dataFim: Date;
  nomeSolicitante?: string;
  cpfSolicitante?: string;
};

function ExportacaoProntuario() {
  const { consultorios } = useAppSelector((state: RootState) => state);

  const [paciente, setPaciente] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [assinarExportacao, setAssinarExportacao] = useState<boolean>(false);

  const {
    webSigner: { handleSign, isSuccess: webSignerIsLoaded },
    isHsm,
    handleSignHSM,
    dialogAssinarDigitalmente,
  } = useAssinatura();

  const formValidation = (): any => {
    return Yup.object().shape({
      nomeSolicitante: Yup.string().when('responsavelLegal', {
        is: true,
        then: Yup.string().required('O campo é obrigatório.'),
        otherwise: Yup.string().notRequired(),
      }),
      cpfSolicitante: Yup.string().when('responsavelLegal', {
        is: true,
        then: Yup.string()
          .required('O campo é obrigatório.')
          .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'CPF inválido.'),
        otherwise: Yup.string().notRequired(),
      }),
      dataInicio: Yup.date().when('prontuarioCompleto', {
        is: false,
        then: Yup.date()
          .nullable()
          .required('O campo é obrigatório.')
          .typeError('Data inválida.'),
        otherwise: Yup.date().nullable().notRequired(),
      }),
      dataFim: Yup.date().when('prontuarioCompleto', {
        is: false,
        then: Yup.date()
          .nullable()
          .required('O campo é obrigatório.')
          .typeError('Data inválida.'),
        otherwise: Yup.date().nullable().notRequired(),
      }),
      justificativa: Yup.string()
        .trim()
        .required('O campo é obrigatório.')
        .min(3, 'Mínimo de 3 caracteres.'),
    });
  };

  const useFormMethods = useForm<FormValues>({
    resolver: yupResolver(formValidation()),
    defaultValues: { prontuarioCompleto: true },
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    watch,
    unregister,
    formState: { errors, isSubmitting, isValid },
  } = useFormMethods;

  const watchProntuarioCompleto = watch('prontuarioCompleto');
  const watchResponsavelLegal = watch('responsavelLegal');
  const watchDataInicio = watch('dataInicio');
  const watchDataFim = watch('dataFim');

  const periodoSelected = watchDataInicio && watchDataFim;

  useEffect(() => {
    const checkAssinarExportacao = async () => {
      setLoading(true);

      fetchParametro('ASSINAR_EXPORTAR')
        .then((response: any) => {
          setAssinarExportacao(JSON.parse(response));
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    checkAssinarExportacao();
  }, []);

  useEffectSkipFirst(() => {
    if (!watchResponsavelLegal)
      unregister(['nomeSolicitante', 'cpfSolicitante']);
  }, [unregister, watchResponsavelLegal]);

  useEffectSkipFirst(() => {
    if (watchProntuarioCompleto) {
      unregister(['dataInicio', 'dataFim']);
    }
  }, [unregister, watchProntuarioCompleto]);

  const signDocument = async (document: any) => {
    if (!document) return;

    if (isHsm) {
      const response = await handleSignHSM(document.base64);
      if (response.data.documents[0].signatures[0].value) {
        return openBase64PDFInNewTab(
          response.data.documents[0].signatures[0].value,
        );
      }
    }

    const base64Assinado = await handleSign(
      {
        documents: [
          {
            content: document.base64,
            contentType: 'BASE64',
          },
        ],
      },
      {
        sequenciaAssinatura: document.sequenciaAssinatura,
        tipoDocumento: 'EXAMES_CIRURGIAS_GUIA',
      },
    );

    await RelatoriosAPI.requestReportExportacaoProntuarioAssinado({
      base64: document.base64,
      base64Assinado: base64Assinado[0].data[0].signature,
    }).then((response: any) => {
      handleOpenPDF(response, 'rel_exportacao_prontuario.pdf');
    });
  };

  const handleRequestReport = async (data: any) => {
    if (assinarExportacao && !(isHsm || webSignerIsLoaded)) {
      toast(<Toast />, {
        data: {
          title: 'Erro ao assinar prontuário!',
          message: 'Verifique sua assinatura e tente novamente!',
          type: 'error',
        },
      });
      return;
    }

    const { cpfSolicitante, dataInicio, dataFim, ...rest } = data;

    const params = {
      idPaciente: paciente?.id,
      idConsultorio: consultorios?.ativo?.id,
      ...rest,
      ...(cpfSolicitante && {
        cpfSolicitante: cpfSolicitante.match(/\d/g).join(''),
      }),
      ...(dataInicio && {
        dataInicio: dayjs(dataInicio).format('YYYYMMDDHHmm'),
      }),
      ...(dataFim && { dataFim: dayjs(dataFim).format('YYYYMMDDHHmm') }),
    };

    if (!assinarExportacao) {
      const response = await RelatoriosAPI.requestReportExportacaoProntuario(
        params,
      );
      if (response.status !== 200) return;

      handleOpenPDF(response, 'rel_exportacao_prontuario.pdf');
      return;
    }

    try {
      const response =
        await RelatoriosAPI.requestReportExportacaoProntuarioAssinar(params, {
          hideToast: true,
          throwError: true,
        });

      return signDocument(response.data);
    } catch {
      return setLoading(false);
    }
  };

  if (!consultorios?.ativo?.id) {
    return (
      <Page id="exportacao-prontuario">
        <>
          <PageHeader title="Exportação de prontuário" subtitle={''} />
          <div className="p-py-2 p-px-4">
            <AlertBox
              visible={true}
              text="Você precisa selecionar um Consultório para esta funcionalidade!"
            />
          </div>
        </>
      </Page>
    );
  }

  if (loading) {
    return (
      <Page white id="exportacao-prontuario">
        <>
          <PageHeader title="Exportação de prontuário" subtitle={''} />

          <div className="p-py-2 p-px-4">
            <GridListLoading />
          </div>
        </>
      </Page>
    );
  }

  return (
    <Page white id="exportacao-prontuario">
      <div className="p-grid p-p-0">
        <div className="p-col-12 p-p-0">
          <PageHeader title="Exportação de prontuário" subtitle={''} />
        </div>
        <div className="p-grid p-col-12 p-lg-8 p-xl-6 p-py-2 p-px-4">
          <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(handleRequestReport)}>
              <div className="p-col-12">
                <SearchPacientes
                  label=""
                  value={paciente}
                  onSelect={(value: any | undefined) => {
                    setPaciente(value);
                  }}
                />
              </div>
              <div className="p-col-12">
                <CheckboxControlled
                  label="Prontuário completo"
                  name="prontuarioCompleto"
                  control={control}
                />
              </div>
              <div className="p-grid">
                <CalendarInputControlled
                  name="dataInicio"
                  control={control}
                  showIcon
                  showTime
                  label="Período inicial"
                  mask="99/99/9999"
                  className="p-col-12 p-sm-6"
                  hideOnDateTimeSelect={false}
                  maxDate={watchDataFim || new Date()}
                  disabled={watchProntuarioCompleto}
                />
                <CalendarInputControlled
                  name="dataFim"
                  control={control}
                  showIcon
                  showTime
                  label="Período final"
                  mask="99/99/9999"
                  className="p-col-12 p-sm-6"
                  hideOnDateTimeSelect={false}
                  maxDate={new Date()}
                  minDate={watchDataInicio}
                  disabled={watchProntuarioCompleto}
                />
              </div>
              <div className="p-grid p-col-12">
                <div className="justificativa p-w-100 p-p-2">
                  <CheckboxControlled
                    label="Responsável legal"
                    name="responsavelLegal"
                    control={control}
                    className="p-col-12"
                  />
                  {watchResponsavelLegal && (
                    <>
                      <TextInputControlled
                        className="p-col-12"
                        name="nomeSolicitante"
                        control={control}
                        label="Nome do solicitante (obrigatório)"
                        errorMsg={errors.nomeSolicitante?.message}
                      />

                      <MaskedInputControlled
                        className="p-col-12"
                        name="cpfSolicitante"
                        control={control}
                        label="CPF do solicitante (obrigatório)"
                        mask={MASK.CPF}
                        errorMsg={errors.cpfSolicitante?.message}
                      />
                    </>
                  )}
                  <TextareaInputControlled
                    name="justificativa"
                    control={control}
                    label="Justificativa (obrigatório)"
                    className="p-col-12"
                    maxLength={250}
                    errorMsg={errors.justificativa?.message}
                  />
                </div>
              </div>
              <div className="p-col-12">
                {watchProntuarioCompleto === false && !periodoSelected ? (
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_40}
                    fontSize={FONT_SIZE.XS}
                  >
                    Para concluir, preencha o período e gere o relatório
                    clicando em abrir em PDF
                  </SimpleText>
                ) : (
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_40}
                    fontSize={FONT_SIZE.XS}
                  >
                    Para concluir, gere o relatório clicando em abrir em PDF
                  </SimpleText>
                )}
              </div>

              <div className="p-col-12">
                <Button
                  className="p-w-fit-content"
                  label="Abrir em PDF"
                  btnType={BtnTypes.TONAL}
                  disabled={!paciente || !isValid}
                  loading={isSubmitting}
                />
              </div>
            </form>
          </FormProvider>
        </div>
        {dialogAssinarDigitalmente.isOpen && (
          <DialogAssinarDigitalmente {...dialogAssinarDigitalmente} />
        )}
      </div>
    </Page>
  );
}

export default ExportacaoProntuario;
