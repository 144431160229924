import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { BiometriaAPI } from 'src/APIs/AgendaAPI/BiometriaAPI/BiometriaAPI';
import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { useAgenda } from '../../AgendaContext';

import BiodocDialog from './BiodocDialog';

interface BiometriaFacialProps {
  cartao: string;
  nomePaciente: string;
  codigoServico?: string;
  biodocDisclosure: DisclosureType;
}

const BiometriaFacial = ({
  cartao,
  nomePaciente,
  codigoServico,
  biodocDisclosure,
}: BiometriaFacialProps) => {
  const { agendamento } = useAgenda();
  const [loading, setLoading] = useState(false);
  const [loadingProvedor, setLoadingProvedor] = useState(false);
  const [provedorBiometria, setProvedorBiometria] = useState<string>('');
  const [tokenBiodoc, setTokenBiodoc] = useState<string>('');

  useEffect(() => {
    if (!agendamento?.id) return;
    (async () => {
      try {
        setLoadingProvedor(true);
        const tipoBiometria = await BiometriaAPI.getProvedorBiometria(
          agendamento?.id,
        );
        setProvedorBiometria(tipoBiometria.data.provedor);
        if (tipoBiometria.data.provedor === 'BIO_DOC') {
          const token = await ParametroAPI.loadParametrosByCodigo(
            {
              codigo: 'TOKEN_BIODOC',
            },
            { throwError: true },
          );
          setTokenBiodoc(token.valor);
        }
      } catch {
      } finally {
        setLoadingProvedor(false);
      }
    })();
  }, [agendamento]);

  const handleContinuar = async () => {
    if (provedorBiometria === 'BIO_DOC') {
      return biodocDisclosure.open();
    }

    try {
      setLoading(true);
      const response = await BiometriaAPI.getBiometriaUnivision(
        agendamento.id,
        cartao,
      );
      if (response.biometriaValida) {
        return window.parent.postMessage(
          { type: 'biometria', status: 'ok' },
          '*',
        );
      } else {
        return toast(<Toast />, {
          data: {
            title: 'Biometria não validada',
            message:
              'Peça que o paciente realize a biometria facial no aplicativo do cliente.',
            type: 'warning',
          },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-d-flex p-flex-column p-mb-1">
      <Skeleton loading={loadingProvedor} height="200px">
        <>
          <SimpleText fontColor="color_40" medium>
            Instruções para o paciente:
          </SimpleText>

          <ul className="p-my-3">
            <li className="p-ml-4 p-my-1">Não usar boné ou chapéu;</li>
            <li className="p-ml-4 p-my-1">
              Permanecer em um ambiente iluminado;
            </li>
            <li className="p-ml-4 p-my-1">Evitar sombras no seu rosto.</li>
          </ul>

          <Button
            label="Continuar"
            className={'p-col-align-center p-d-flex'}
            onClick={() => handleContinuar()}
            loading={loading}
            disabled={!provedorBiometria}
            stretch
          />
        </>
      </Skeleton>
      {biodocDisclosure.isOpen && (
        <BiodocDialog
          cartao={cartao}
          nomePaciente={nomePaciente}
          codigoServico={codigoServico || ''}
          tokenBiodoc={tokenBiodoc}
          {...biodocDisclosure}
        />
      )}
    </div>
  );
};

export default BiometriaFacial;
