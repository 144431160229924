import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { SidebarProps } from 'primereact/sidebar';
import { TieredMenu } from 'primereact/tieredmenu';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from 'react-stomp-hooks';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import AutorizacaoAPI from 'src/APIs/AgendaAPI/AutorizacaoAPI';
import { ProcedimentosSeriadosAPI } from 'src/APIs/AgendaAPI/ProcedimentosSeriadosAPI';
import { TelemedicinaSalaAtendimentoAPI } from 'src/APIs/ConfigAPI/TelemedicinaSalaAtendimento/TelemedicinaSalaAtendimento';
import * as Yup from 'yup';

import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import { setNewAgendamentoProcessandoTiss } from 'src/core/redux/slices/tiss/TissSlice';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';
import { useThrottle } from 'src/utils/hooks/useThrottle';

import { TelemedicinaConfirm } from './components/TelemedicinaConfirm';
import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Toast from 'src/components/Basics/Toast/Toast';
import { DialogLoadingTiss } from 'src/components/DialogLoadingTiss/DialogLoadingTiss';
import Sidebar from 'src/components/Sidebar/Sidebar';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { useAgenda } from '../AgendaContext';

import BiometriaFacial from './BiometriaFacial/BiometriaFacial';
import { DialogErrorTiss } from './DialogErrorTiss/DialogErrorTiss';
import FormaPagamentoItem from './FormaPagamentoItem/FormaPagamentoItem';
import Token from './Token/Token';
import { TokenSMS } from './TokenSMS/TokenSMS';
import { getNumCartao, isCartaoLido } from './validaCartao';

import './MenuConfirmarPresenca.scss';

type MenuConfirmarPresencaProps = SidebarProps & DisclosureType;

const MenuConfirmarPresenca = ({
  close,
  state,
  ...rest
}: MenuConfirmarPresencaProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { agenda, consultorios, tiss } = useAppSelector(
    (state: RootState) => state,
  );
  const {
    agendamento,
    dialogProcedimentosSeriados,
    refetchAgenda,
    dialogImprimirEtiqueta,
    reloadList,
    getGrupo,
  } = useAgenda();
  const biodocDisclosure = useDisclosure({ opened: false });

  const servicoTuss = agendamento.servicoTuss;

  const nomePaciente = agendamento.paciente?.nome;
  const codigoServico = agendamento.servicoTuss?.codigo;

  const onCloseExternalDialog = state.onCloseExternalDialog;
  const isAgendamentoGrupo = !!agendamento.idGrupoAtendimento;

    const dialogErrorTiss = useDisclosure({ opened: false });
    const dialogLoadingTiss = useDisclosure({ opened: false });

    const validatioSchema = Yup.object().shape({
      especialidade: Yup.number().required('Selecione uma especialidade'),
      sala: Yup.number().required('Selecione uma sala'),
    });

    const inputValue = useRef('');

    const confirmaCartao =
      agenda.profissionalAtivo.profissionalSaude.confirmacaoCartao;

    const [buttonSelected, setButtonSelected] = useState('');
    const [alertVisible, setAlertVisible] = useState(false);
    const [numeroGA, setNumeroGA] = useState('');
    const [valueCartao, setValueCartao] = useState('');

    const [step, setStep] = useState<number>(0);

    const [loading, setLoading] = useState(false);
    const [loadingDrawer, setLoadingDrawer] = useState(false);

    const [dislabedBtn, setDislabedBtn] = useState(true);

    const [cartoes, setCartoes] = useState<CartaoPaciente[]>([]);
    const [cartaoSelected, setCartaoSelected] = useState<CartaoPaciente | null>(
      null,
    );

    const [salas, setSalas] = useState([]);
    const [salasOptions, setSalasOptions] = useState<any>([]);
    const [especialidadesOptions, setEspecialidadesOptions] = useState<any>([]);

    const [validado, setValidado] = useState(false);

    const tokenRef = useRef<OverlayPanel>(null);

    const tokenOptions = [
      {
        label: 'Token SMS',
        command: () => [
          setButtonSelected('token_sms'),
          tokenRef.current?.hide(),
        ],
      },
      {
        label: 'Token APP',
        command: () => [
          setButtonSelected('token_app'),
          tokenRef.current?.hide(),
        ],
      },
    ];

    const isTeleconsulta = agendamento.flagsAtivas?.find(
      (item: string) => item === 'TELECONSULTA',
    );

    useSubscription('/user/topic/notificacao', (message: any) => {
      const data = JSON.parse(message.body);

      if (data.dadosComplementares.statusAtendimento === 'PRESENTE') {
        if (consultorios?.ativo?.identificacaoEtiqueta) {
          dialogImprimirEtiqueta.open({
            state: cartaoSelected?.numeroCartao,
          });
        }
      }

      if (
        data.tipoNotificacao === 'TISS' &&
        data.funcionalidade === 'AGENDA' &&
        data?.dadosComplementares?.idAtendimento == agendamento.id &&
        (data?.dadosComplementares?.transacao === 'SADT' ||
          data?.dadosComplementares?.transacao === 'STATUS_AUTORIZACAO')
      ) {
        setValidado(true);

        if (onCloseExternalDialog) {
          onCloseExternalDialog();
        }

        if (isTeleconsulta) {
          return dialogLoadingTiss.close();
        }

        reloadList();
        close();
      }
    });

    const {
      control,
      handleSubmit,
      formState: { errors, isSubmitting },
      setValue,
      watch,
      trigger,
    } = useForm({
      mode: 'onChange',
      resolver: yupResolver(validatioSchema),
    });

    useEffect(() => {
      if (servicoTuss) {
        servicoTuss.label = `${servicoTuss.codigo} - ${servicoTuss.nome}`;
      }
    }, [servicoTuss]);

    useEffect(() => {
      if (salasOptions.length === 1) {
        setValue('sala', salasOptions[0]?.value);
      }
      if (especialidadesOptions.length === 1) {
        setValue('especialidade', especialidadesOptions[0]?.value);
      }
    }, [especialidadesOptions, salasOptions, setValue]);

    const especialidade = watch('especialidade');
    const sala = watch('sala');

    const salaURL = useRef('');

    useEffect(() => {
      if (isTeleconsulta) {
        setLoadingDrawer(true);

        TelemedicinaSalaAtendimentoAPI.getSalaAtendimento({
          idAtendimento: agendamento.id,
        })
          .then(sala => {
            salaURL.current = sala.link;
          })
          .finally(() => setLoadingDrawer(false));
      }
    }, [agendamento.id, isTeleconsulta]);

    useEffectSkipFirst(() => {
      if (
        step === 1 &&
        agendamento.flagsAtivas?.includes('PROCEDIMENTO') &&
        cartaoSelected?.convenio?.habilitarConsultaProcedimentosSeriados
      ) {
        setLoadingDrawer(true);

        ProcedimentosSeriadosAPI.loadProcedimentosSeriados(
          {
            idAtendimento: agendamento?.id,
            idConvenio: Number(cartaoSelected?.convenio?.id),
            numeroCartao: cartaoSelected?.numeroCartao || '',
          },
          { throwError: true },
        )
          .then(({ data, status }) => {
            if (status === 204) {
              return [refetchAgenda(), reloadList(), close()];
            }

            if (!!data?.length) {
              dialogProcedimentosSeriados.open({
                state: {
                  procedimentosSeriados: data,
                  idConvenio: cartaoSelected?.idConvenio,
                  idEmpresa: consultorios.ativo.idEmpresa,
                  idAtendimento: agendamento?.id,
                  numeroCartao: cartaoSelected?.numeroCartao,
                  tipoValidacao: 'CARTAO',
                  tipoConvenio: cartaoSelected?.convenio.tipoConvenio,
                  idSala: sala,
                  idEspecialidade: especialidade,
                  validaCartao: cartaoSelected?.convenio.validaCartao,
                  status: 'PRESENTE',
                },
              });

              return close();
            }
          })
          .catch(() => {})
          .finally(() => setLoadingDrawer(false));
      }
    }, [step]);

    const cartaoInputRef = useRef<any>();

    const getCartoes = useCallback(async () => {
      try {
        const params = {
          idPaciente: agendamento.idPaciente || agendamento.paciente?.id,
          somenteAtivos: 'true',
        };

        const { data } = await AutorizacaoAPI.getCartoes(params, {
          throwError: true,
        });

        const sortedCartoes = data.sort((cartao: CartaoPaciente) => {
          if (cartao.principal) return -1;
          return 1;
        });

        setCartoes(sortedCartoes);
      } catch {
        close();
      }
    }, [agendamento.idPaciente, agendamento.paciente?.id, close]);

    const getSalas = useCallback(async () => {
      const response = await AutorizacaoAPI.getSalas(consultorios?.ativo?.id, {
        params: { ativo: true },
      });

      setSalas(response.data);
    }, [consultorios?.ativo?.id]);

    const cartaoElegibilidade = useMemo(
      () =>
        cartoes?.length
          ? cartoes.find(c => c.numeroCartao === agendamento?.cartaoPlano)
          : null,
      [cartoes, agendamento?.cartaoPlano],
    );

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);

        await Promise.all([getSalas(), getCartoes()]);

        setLoading(false);
      };

      fetchData();
    }, [getCartoes, getSalas]);

    useEffect(() => {
      if (cartaoElegibilidade && !cartaoSelected) {
        setCartaoSelected(cartaoElegibilidade);
      }
    }, [cartaoElegibilidade, cartaoSelected, cartoes]);

    useEffect(() => {
      const salasFormatadas: any = salas?.map((item: any) => ({
        label: item.nome,
        value: item.id,
      }));

      const especialidades =
        agenda?.profissionalAtivo?.profissionalSaude?.especialidades.map(
          (item: any) => ({
            label: item.nome,
            value: item.id,
          }),
        );

      setSalasOptions(salasFormatadas);
      setEspecialidadesOptions(especialidades);
    }, [agenda?.profissionalAtivo?.profissionalSaude?.especialidades, salas]);

    const handleSuccess = useCallback(() => {
      setValidado(true);

      if (isAgendamentoGrupo && agendamento.idAtendimentoEmGrupo) {
        getGrupo(agendamento.idAtendimentoEmGrupo);
      }

      if (!cartaoSelected?.utilizaTiss) {
        const toastData = {
          title: 'Sucesso',
          message: 'Autorização realizada com sucesso.',
          type: 'success',
        };

        toast(<Toast />, { data: toastData });
      }

      if (!isTeleconsulta) {
        refetchAgenda();
        reloadList();
      }

      const isRetorno = agendamento?.flagsAtivas?.includes('RETORNO');

      if (
        consultorios?.ativo?.identificacaoEtiqueta &&
        (isRetorno || !cartaoSelected?.utilizaTiss)
      ) {
        dialogImprimirEtiqueta.open({
          state: cartaoSelected?.numeroCartao,
        });
      }

      if (onCloseExternalDialog) {
        onCloseExternalDialog();
      }

      if (!isTeleconsulta) {
        close();
      }
    }, [
      agendamento.idAtendimentoEmGrupo,
      cartaoSelected?.utilizaTiss,
      close,
      consultorios?.ativo?.identificacaoEtiqueta,
      dialogImprimirEtiqueta,
      getGrupo,
      isAgendamentoGrupo,
      isTeleconsulta,
      onCloseExternalDialog,
      refetchAgenda,
      reloadList,
      cartaoSelected?.numeroCartao,
      agendamento?.flagsAtivas,
    ]);

    const submit = useCallback(
      async cartao => {
        try {
          setLoading(true);
          const payload = {
            idConvenio: cartaoSelected?.idConvenio,
            idEmpresa: consultorios.ativo.idEmpresa,
            idSala: sala,
            idEspecialidade: especialidade,
            tipoConvenio: cartaoSelected?.convenio.tipoConvenio,
            validaCartao: cartaoSelected?.convenio.validaCartao,
            tipoValidacao: buttonSelected,
            flagsAtendimentos: agendamento.flagsAtivas,
            ...(buttonSelected === 'BIOMETRIA' && { numeroCartao: cartao }),
            ...(buttonSelected === 'CARTAO' && { numeroCartao: cartao }),
            ...(buttonSelected === 'GA' && {
              guia: numeroGA,
              numeroCartao: cartaoSelected?.numeroCartao,
            }),
            status: 'PRESENTE',
          };

          await AutorizacaoAPI.realizarPresenca(agendamento.id, payload, {
            throwError: true,
          });

          if (isAgendamentoGrupo && !agendamento.tissSadtSolicitado) {
            return handleSuccess();
          }

          if (
            !agenda?.profissionalAtivo?.profissionalSaude
              ?.naoRealizaTransacaoTiss &&
            !agendamento.flagsAtivas?.includes('PROCEDIMENTO')
          ) {
            dispatch(
              setNewAgendamentoProcessandoTiss([
                ...(tiss?.processandoTiss || []),
                {
                  idAgendamento: agendamento.id,
                  payload,
                },
              ]),
            );

            if (cartaoSelected?.utilizaTiss) {
              return dialogLoadingTiss.open();
            }
          }

          handleSuccess();
        } catch (error) {
          biodocDisclosure.close();
        } finally {
          setLoading(false);
        }
      },
      [cartaoSelected?.idConvenio, cartaoSelected?.convenio.tipoConvenio, cartaoSelected?.convenio.validaCartao, cartaoSelected?.numeroCartao, cartaoSelected?.utilizaTiss, consultorios.ativo.idEmpresa, sala, especialidade, buttonSelected, agendamento.flagsAtivas, agendamento.id, agendamento.tissSadtSolicitado, numeroGA, isAgendamentoGrupo, agenda?.profissionalAtivo?.profissionalSaude?.naoRealizaTransacaoTiss, handleSuccess, dispatch, tiss?.processandoTiss, dialogLoadingTiss, biodocDisclosure],
    );

  useEffect(() => {
    const handleBiometria = (e: any) => {
      if (e.data.type === 'biometria') {
        submit(cartaoSelected?.numeroCartao);
      }
    };

    window.addEventListener('message', handleBiometria);
    return () => {
      window.removeEventListener('message', handleBiometria);
    };
  }, [cartaoSelected?.numeroCartao, submit]);

  const submitFlags = useCallback(
    async (data: any) => {
      const isRetorno = agendamento.flagsAtivas?.includes('RETORNO');

      const payload = {
        idConvenio: cartaoSelected?.idConvenio || agendamento.idConvenio,
        idEmpresa: consultorios.ativo.idEmpresa,
        idSala: data.sala,
        idEspecialidade: data.especialidade,
        tipoConvenio: isRetorno ? 'UNIMED' : undefined,
        numeroCartao: isRetorno ? agendamento.cartaoPlano : undefined,
        status: 'PRESENTE',
        flagsAtendimentos: agendamento.flagsAtivas,
      };

      const response = await AutorizacaoAPI.realizarPresenca(
        agendamento.id,
        payload,
        { return: true },
      );

      if (response.status === 200) {
        if (
          !agenda?.profissionalAtivo?.profissionalSaude?.naoRealizaTransacaoTiss
        ) {
          dispatch(
            setNewAgendamentoProcessandoTiss([
              ...(tiss?.processandoTiss || []),
              {
                idAgendamento: agendamento.id,
                payload,
              },
            ]),
          );
        }
        handleSuccess();
      }
    },
    [
      agendamento.flagsAtivas,
      agendamento.idConvenio,
      agendamento.cartaoPlano,
      agendamento.id,
      cartaoSelected?.idConvenio,
      consultorios.ativo.idEmpresa,
      agenda?.profissionalAtivo?.profissionalSaude?.naoRealizaTransacaoTiss,
      handleSuccess,
      dispatch,
      tiss?.processandoTiss,
    ],
  );

  const handleValidaCartao = useCallback(
    async (value: string) => {
      if (cartaoSelected) {
        if (value === cartaoSelected?.numeroCartao) {
          setDislabedBtn(false);
          setAlertVisible(false);

          submit(value);
        } else {
          setDislabedBtn(true);
          setAlertVisible(true);
        }
      }
    },
    [cartaoSelected, submit],
  );

  const handleLeituraCartao = useCallback(
    (value = '') => {
      const mask = cartaoSelected?.convenio?.mascaraCartao
        ? cartaoSelected.convenio.mascaraCartao
        : '9999.9999.999999.99-9';

      const carteirinha = getNumCartao(value, mask);

      if (isCartaoLido(value)) {
        setValueCartao(carteirinha);
        handleValidaCartao(carteirinha);
        inputValue.current = '';
      }
    },
    [cartaoSelected, handleValidaCartao],
  );

  const handleLeitura = useThrottle(handleLeituraCartao, 50);

  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => {
      inputValue.current += e.key;
      handleLeitura(inputValue.current);
    };

    window.addEventListener('keydown', keydownListener);

    return () => {
      window.removeEventListener('keydown', keydownListener);
    };
  }, [handleLeitura, handleLeituraCartao]);

  const handleTextInput = async (e: any) => {
    const value = e.target.value;

    setValueCartao(value);
    handleValidaCartao(value);
  };

  const isFlags = useCallback((): boolean => {
    if (
      agendamento.flagsAtivas?.includes('PARTICULAR') ||
      agendamento.flagsAtivas?.includes('RETORNO')
    ) {
      return true;
    }

    return false;
  }, [agendamento.flagsAtivas]);

  useEffect(() => {
    if (buttonSelected === 'CARTAO') {
      cartaoInputRef?.current?.select();
    }
  }, [buttonSelected]);

  const handleNextStep = async () => {
    const isValid = await trigger(['especialidade', 'sala']);

    if (isValid) setStep(1);
  };

  const handleCancelTissProccess = async () => {
    try {
      await AutorizacaoAPI.sendCancelarProcessamentoTiss(agendamento.id);
    } catch {}
  };

  return (
    <Sidebar
      position="right"
      {...rest}
      visible
      onHide={() => close()}
      loading={loadingDrawer}
      header={
        <SimpleText fontColor={FONT_COLOR.COLOR_16} className="title" bold>
          Registrar presença
        </SimpleText>
      }
      className="MenuConfirmarPresenca"
    >
      <>
        {dialogLoadingTiss.isOpen && (
          <DialogLoadingTiss
            customMensage={cartaoSelected?.mensagemConexaoOperadora}
            onTimeout={() => dialogErrorTiss.open()}
            timeout={cartaoSelected?.timeoutSolicitacaoProcedimento || 10}
            setIsTimerRunning={() => {}}
            {...dialogLoadingTiss}
          />
        )}

        {dialogErrorTiss.isOpen && (
          <DialogErrorTiss
            customMensage={cartaoSelected?.mensagemFalhaComunicacao}
            onRetry={() => dialogLoadingTiss.open()}
            onCancel={handleCancelTissProccess}
            onFinish={handleSuccess}
            {...dialogErrorTiss}
          />
        )}

        {isTeleconsulta && salaURL && (
          <TelemedicinaConfirm
            agendamentoId={agendamento.id}
            salaURL={salaURL.current}
          />
        )}

        <div className="MenuConfirmarPresenca-content">
          {step === 0 && (
            <>
              {loading && <ComponentsLabel label="Sala de atendimento" />}
              <Skeleton
                className="p-mb-3"
                loading={loading}
                height="33px"
                borderRadius="8px"
              >
                <DropdownControlled
                  name="sala"
                  disabled={validado}
                  label="Sala de atendimento"
                  className="p-mb-3"
                  placeholder="Selecione uma sala"
                  control={control}
                  options={salasOptions}
                  errorMsg={errors.sala?.message}
                />
              </Skeleton>
              {loading && <ComponentsLabel label="Especialidade" />}
              <Skeleton
                className="p-mb-5"
                loading={loading}
                height="33px"
                borderRadius="8px"
              >
                <DropdownControlled
                  name="especialidade"
                  disabled={validado}
                  label="Especialidade"
                  className="p-mb-5"
                  placeholder="Selecione uma especialidade"
                  control={control}
                  options={especialidadesOptions}
                  errorMsg={errors.especialidade?.message}
                />
              </Skeleton>

              {loading && (
                <>
                  <SimpleText fontSize={FONT_SIZE.XS} bold>
                    Cartão
                  </SimpleText>

                  {new Array(3).fill(undefined).map((_, index) => (
                    <Skeleton
                      key={index}
                      className="p-my-2"
                      loading={true}
                      height="83px"
                      borderRadius="10px"
                    />
                  ))}
                </>
              )}

              <div className="cartoes">
                {!isFlags() && !!cartoes.length && !loading && (
                  <>
                    <SimpleText fontSize={FONT_SIZE.XS} bold>
                      Cartão
                    </SimpleText>

                    {!loading && servicoTuss
                      ? cartoes
                          .filter(
                            (item: any) =>
                              item.numeroCartao === agendamento.cartaoPlano,
                          )
                          .map((item: any) => (
                            <>
                              <FormaPagamentoItem
                                key={item.id}
                                name="cartao"
                                cartao={item}
                                checked={cartaoSelected === item}
                                src={item.convenio?.urlLogotipo}
                                onClick={() => setCartaoSelected(item)}
                                alertMsg={
                                  cartoes.length > 1
                                    ? 'O paciente possui outros cartões!'
                                    : null
                                }
                              />
                              <Dropdown
                                disabled
                                label="Serviço"
                                options={[servicoTuss]}
                                optionLabel="label"
                                value={servicoTuss}
                                className="p-mt-2 p-mb-1"
                              />
                              <Button
                                stretch
                                label="Editar o agendamento"
                                btnType="outline"
                                onClick={() =>
                                  navigate('/agenda/novo-agendamento', {
                                    state: { editAgendamento: agendamento },
                                  })
                                }
                              />
                            </>
                          ))
                      : cartoes.map((item: any) => (
                          <FormaPagamentoItem
                            key={item.id}
                            name="cartao"
                            cartao={item}
                            checked={cartaoSelected === item}
                            src={item.convenio?.urlLogotipo}
                            onClick={() => setCartaoSelected(item)}
                          />
                        ))}
                  </>
                )}
              </div>
            </>
          )}

          {step === 1 && (
            <div>
              <SimpleText fontColor={FONT_COLOR.COLOR_40} className="p-mt-3">
                Para concluir solicite a autorização do paciente. Pergunte ao
                paciente qual a melhor forma para liberação:
              </SimpleText>

              <div className="auth-items-container p-mt-4">
                <button
                  onClick={() => setButtonSelected('CARTAO')}
                  disabled={validado}
                  className={`auth-button ${
                    buttonSelected === 'CARTAO' && 'selected'
                  }`}
                >
                  <i className="fas fa-chevron-right auth-button-icon" />
                  <span
                    className={`auth-button-label  ${
                      buttonSelected === 'CARTAO' && 'selected'
                    }`}
                  >
                    Carteirinha
                  </span>
                </button>

                <button
                  onClick={e => [
                    setButtonSelected('token-option'),
                    tokenRef.current?.toggle(e),
                  ]}
                  disabled={validado}
                  className={`auth-button hover-container ${
                    buttonSelected === 'token' && 'selected'
                  }`}
                >
                  <i className="fas fa-key auth-button-icon" />
                  <span
                    className={`auth-button-label  ${
                      buttonSelected === 'token-option' && 'selected'
                    }`}
                  >
                    {buttonSelected === 'token-option'
                      ? 'Token'
                      : buttonSelected === 'token_sms'
                      ? 'Token SMS'
                      : 'Token APP'}
                  </span>
                  <i
                    className={`fas fa-chevron-down hover-icon ${
                      buttonSelected === 'token-option' && 'selected'
                    }`}
                  />
                </button>
                <TieredMenu model={tokenOptions} popup ref={tokenRef} />

                <button
                  onClick={() => setButtonSelected('BIOMETRIA')}
                  disabled={validado}
                  className={`auth-button ${
                    buttonSelected === 'BIOMETRIA' && 'selected'
                  }`}
                >
                  <i className="fas fa-fingerprint auth-button-icon" />
                  <span
                    className={`auth-button-label  ${
                      buttonSelected === 'BIOMETRIA' && 'selected'
                    }`}
                  >
                    Biometria
                  </span>
                </button>

                <button
                  onClick={() => setButtonSelected('GA')}
                  disabled={validado}
                  className={`auth-button ${
                    buttonSelected === 'GA' && 'selected'
                  }`}
                >
                  <i className="fas fa-newspaper auth-button-icon" />
                  <span
                    className={`auth-button-label  ${
                      buttonSelected === 'GA' && 'selected'
                    }`}
                  >
                    Guia (GA)
                  </span>
                </button>
              </div>

              {buttonSelected === 'CARTAO' && (
                <>
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_40}
                    className="p-mt-5"
                  >
                    Passe o cartão do plano de saúde escolhido pelo paciente na
                    leitora.
                  </SimpleText>

                  <TextInput
                    id="cartao"
                    name="cartao"
                    className="p-mt-2"
                    onChange={handleTextInput}
                    maxLength={30}
                    disabled={validado}
                    value={valueCartao}
                    readOnly={
                      confirmaCartao &&
                      cartaoSelected?.convenio.tipoConvenio === 'UNIMED'
                    }
                    ref={cartaoInputRef}
                    //onKeyDown={handleLeituraCartao}
                    // onBlur={handleValidaCartao}
                    // readOnly={!dislabedBtn}
                    // onChange={(e: any) => setValueCartao(e.target.value)}
                  />
                </>
              )}

              {buttonSelected === 'token_app' && (
                <Token
                  onHide={handleSuccess}
                  idEspecialidade={especialidade}
                  idSala={sala}
                  cartaoSelected={cartaoSelected}
                />
              )}

              {buttonSelected === 'token_sms' && (
                <TokenSMS
                  onHide={handleSuccess}
                  idEspecialidade={especialidade}
                  idSala={sala}
                  cartaoSelected={cartaoSelected}
                />
              )}

              {buttonSelected === 'BIOMETRIA' && (
                <BiometriaFacial
                  cartao={cartaoSelected?.numeroCartao || ''}
                  nomePaciente={nomePaciente}
                  codigoServico={codigoServico}
                  biodocDisclosure={biodocDisclosure}
                />
              )}

              {buttonSelected === 'GA' &&
                (!!cartaoSelected?.numeroCartao ? (
                  <TextInput
                    label="Numero GA"
                    value={numeroGA}
                    onChange={t => setNumeroGA(t.target.value)}
                    className="p-mt-3"
                  />
                ) : (
                  <AlertBox
                    text="Selecione o cartão do paciente na etapa anterior para seguir com a presença pela guia de autorização."
                    visible={true}
                  />
                ))}
            </div>
          )}

          <AlertBox
            text="o cartão selecionado é diferente do cartão inserido."
            visible={alertVisible}
            onHide={() => setAlertVisible(false)}
          />
        </div>

        {isTeleconsulta && validado ? (
          <div className="p-d-flex p-ai-center p-jc-arround p-mt-auto">
            <Button
              stretch
              label={'Encerrar Chamada'}
              btnType="danger"
              onClick={() => [close(), refetchAgenda(), reloadList()]}
            />
          </div>
        ) : (
          <div className="p-d-flex p-ai-center p-jc-arround p-mt-auto">
            {isFlags() || !cartoes.length ? (
              <Button
                stretch
                label={'Salvar'}
                onClick={handleSubmit(submitFlags)}
                loading={isSubmitting}
              />
            ) : (
              <>
                <Button
                  className="p-flex-1 p-mr-5"
                  label="Voltar"
                  btnType="ghost"
                  disabled={step === 0}
                  onClick={() => {
                    setStep(0);
                    setValueCartao('');
                  }}
                />

                {buttonSelected === 'GA' && step === 1 ? (
                  <Button
                    className="p-flex-3"
                    label="Autenticar guia"
                    onClick={handleSubmit(() => submit(valueCartao))}
                    loading={isSubmitting}
                    disabled={
                      numeroGA.length < 8 || !cartaoSelected?.numeroCartao
                    }
                  />
                ) : (
                  <Button
                    className="p-flex-3"
                    label={step === 0 ? 'Próximo' : 'Salvar'}
                    onClick={
                      step === 1
                        ? handleSubmit(() => submit(valueCartao))
                        : () => handleNextStep()
                    }
                    loading={isSubmitting}
                    disabled={loading || (dislabedBtn && step === 1)}
                  />
                )}
              </>
            )}
          </div>
        )}
      </>
    </Sidebar>
  );
};

export default memo(MenuConfirmarPresenca);
