import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/relatorios';

const relatorioGraficos = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `${basePath}/perimetro-cefalico/pdf`,
    responseType: 'blob',
    data: payload,
    hideToast: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    return: true,
    ...options,
  });

const relatorioGraficoDnpm = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `${basePath}/desenvolvimento-neuropsicomotor/pdf`,
    responseType: 'blob',
    data: payload,
    hideToast: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    return: true,
    ...options,
  });

const relatorioAtendimentoEncaminhamento = (
  payload: any,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `${basePath}/atendimento-encaminhamento/pdf`,
    responseType: 'blob',
    data: payload,
    params,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    return: true,
    ...options,
  });

const relatorioAtendimentoContrarreferencia = (
  payload: any,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `${basePath}/atendimento-contrarreferencia/pdf`,
    responseType: 'blob',
    data: payload,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params,
    return: true,
    ...options,
  });

const relatorioAtendimentoContrarreferenciaPorEmail = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `${basePath}/atendimento-contrarreferencia/pdf/enviar-por-email`,
    data: payload,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    return: true,
    ...options,
  });

const relatorioAtendimentoEncaminhamentoPorEmail = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `${basePath}/atendimento-encaminhamento/pdf/enviar-por-email`,
    data: payload,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    return: true,
    ...options,
  });

const relatorioPrescricao = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `${basePath}/prescricao/pdf`,
    params: payload,
    return: true,
    ...options,
  });

const relatorioPrescricaoAssinado = (
  idAtendimento: any,
  data: { base64: string; base64Assinado: string },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `${basePath}/prescricao/${idAtendimento}`,
    data,
    responseType: 'arraybuffer',
    return: true,
    ...options,
  });

const relatorioPrescricaoImprimir = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `${basePath}/prescricao/imprimir`,
    responseType: 'arraybuffer',
    params: payload,
    return: true,
    ...options,
  });

const relatorioTermoConsentimento = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `relatorios-adm/termo-consentimento/${payload.idLogEmail}/pdf`,
    responseType: 'arraybuffer',
    return: true,
    ...options,
  });

const relatorioParto = (
  params: {
    dataPrevisaoInicio: string;
    dataPrevisaoFinal: string;
    idConsultorio: number;
    idProfissionalSaude: number;
    idPaciente?: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `relatorios-pep/previsao-parto/pdf`,
    params,
    responseType: 'arraybuffer',
    throwError: true,
    return: true,
    ...options,
  });

const relatorioPermissoesAcesso = (
  data: { idPerfilList: number[] },
  type: 'pdf' | 'excel',
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `relatorios-adm/permissao-acesso/${type}`,
    responseType: 'arraybuffer',
    data,
    return: true,
    ...options,
  });

const postDocumentoAssinadoPDF = (
  id: number | string,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `relatorios/${id}/pdf-assinado`,
    data: payload,
    return: true,
    responseType: 'arraybuffer',
    ...options,
  });

const enviarPorEmailAssinado = (
  id: number | string,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `relatorios/${id}/enviar-por-email-assinado`,
    data: payload,
    responseType: 'arraybuffer',
    ...options,
  });

const ReportAPI = {
  relatorioGraficos,
  relatorioPrescricao,
  relatorioPrescricaoAssinado,
  relatorioPrescricaoImprimir,
  relatorioGraficoDnpm,
  relatorioTermoConsentimento,
  relatorioAtendimentoEncaminhamento,
  relatorioAtendimentoContrarreferencia,
  relatorioAtendimentoContrarreferenciaPorEmail,
  relatorioAtendimentoEncaminhamentoPorEmail,
  relatorioParto,
  relatorioPermissoesAcesso,
  postDocumentoAssinadoPDF,
  enviarPorEmailAssinado,
};

export default ReportAPI;
