import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';

import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';
import Skeleton from 'src/components/Skeleton/Skeleton';

interface Props {
  className?: string;
  idConsultorioEdit?: number | null;
  name?: string;
  setConsultoriosList?: (consultorios: any) => void;
  somenteUsuario?: boolean;
}

const ConsultorioMultiSelect = ({
  className,
  name = 'idConsultorio',
  setConsultoriosList,
  somenteUsuario,
}: Props) => {
  const [consultorios, setConsultorios] = useState<GetConsultorioDTO[]>([]);
  const [prevConsultorios, setPrevConsultorios] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const watchConsultorio = watch(name);

  const loadUserConsultorios = useCallback(async () => {
    try {
      const response = await ConsultorioAPI.loadUserConsultorios();
      setConsultorios(response);
      setConsultoriosList?.(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [setConsultoriosList]);

  const loadAllConsultorios = useCallback(async () => {
    try {
      const response = await ConsultorioAPI.loadConsultorios({
        nome: '',
        pageSize: 9999,
      });
      setConsultorios(response.list);
      setConsultoriosList?.(response.list);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [setConsultoriosList]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (somenteUsuario) {
        await loadUserConsultorios();
      } else {
        await loadAllConsultorios();
      }
    })();
  }, [loadAllConsultorios, loadUserConsultorios, somenteUsuario]);

  useEffect(() => {
    setPrevConsultorios(watchConsultorio);

    if (
      !prevConsultorios?.includes('todos') &&
      prevConsultorios?.length > 0 &&
      watchConsultorio?.includes('todos')
    ) {
      setValue(name, ['todos']);
    }

    if (prevConsultorios?.includes('todos') && watchConsultorio?.length > 1) {
      const filterTodos = watchConsultorio.filter((id: any) => id !== 'todos');
      setValue(name, filterTodos);
    }
  }, [name, prevConsultorios, setValue, watchConsultorio]);

  const options = useMemo(() => {
    return [
      {
        nome: 'Todos',
        id: 'todos',
      },
      ...consultorios,
    ];
  }, [consultorios]);

  useEffect(() => {
    if (consultorios.length === 1) {
      setValue(name, [consultorios[0]?.id]);
    }
  }, [consultorios, name, setValue]);

  if (loading) return <Skeleton height="30px" loading />;

  return (
    <MultiSelectControlled
      control={control}
      label="Consultório"
      name={name}
      options={options}
      placeholder="Selecione"
      optionLabel="nome"
      optionValue="id"
      errorMsg={errors.idConsultorio?.message}
      className={className || ''}
    />
  );
};

export default ConsultorioMultiSelect;
