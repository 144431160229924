import { useRef } from 'react';

import { ButtonProps, Button as PrimeButton } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';

import useTheme from 'src/core/themes/useTheme';

import Can from 'src/components/Can/Can';

import './style.scss';

export type BtnTypes =
  | 'default'
  | 'tonal'
  | 'outline'
  | 'ghost'
  | 'danger'
  | 'gray'
  | 'green-link'
  | 'warn'
  | 'pill'
  | 'light-danger'
  | 'success'
  | 'light-success'
  | 'danger-link'
  | 'light-warning';

type IProps = ButtonProps & {
  badgePos?: 'left' | 'right';
  badgeVariant?: 'default' | 'warn' | 'danger';
  stretch?: boolean;
  checkPermission?: string | string[];
  btnType?: BtnTypes;
  btnSize?: 'small' | 'medium' | 'large';
  overlay?: JSX.Element | ((OverlayPanel: OverlayPanel | null) => JSX.Element);
};

export const Button = ({
  btnType = 'default',
  className = '',
  badgePos = 'left',
  checkPermission = '',
  btnSize,
  overlay,
  ...props
}: IProps) => {
  const { theme } = useTheme();

  const full = !!props.stretch ? 'stretch' : '';

  const overlayRef = useRef<OverlayPanel>(null);

  return (
    <Can checkPermission={checkPermission}>
      <>
        <PrimeButton
          className={`Button-ui ${theme} ${btnType} ${className} ${full} ${btnSize}`}
          badgeClassName={`badge-${badgePos} p-mr-2`}
          data-badgeVariant={props.badgeVariant || 'default'}
          type={props.type || 'button'}
          onClick={e => {
            if (overlay) {
              overlayRef.current?.toggle(e);
            }
            props.onClick && props.onClick(e);
          }}
          {...props}
        />
        {overlay && (
          <OverlayPanel ref={overlayRef}>
            {typeof overlay === 'function'
              ? overlay(overlayRef.current)
              : overlay}
          </OverlayPanel>
        )}
      </>
    </Can>
  );
};
