import dayjs from 'dayjs';
import { IFamiliarPaciente } from 'src/APIs/ProntuarioAPI/FamiliarAPI/Familiar';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter } from 'src/utils/utils';

import Avatar from 'src/components/Avatar/Avatar';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';
import Tooltip from 'src/components/Tooltip/Tooltip';

interface MobileRowProps {
  familiar: IFamiliarPaciente;
  menuItens: any[];
  listaCidsModal: DisclosureType;
  loading: boolean;
}

const MobileRow = ({
  familiar,
  menuItens,
  listaCidsModal,
  loading,
}: MobileRowProps) => {
  return (
    <SearchListMobileRow options={menuItens} loading={loading}>
      <div className="p-grid">
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-pl-0">
          <Avatar
            label={familiar?.nome}
            className="avatar"
            image={familiar.urlFoto || ''}
          />
          <div className="p-d-flex p-flex-column p-gap-1">
            <SimpleText fontColor="color_60" fontSize="xs">
              Nome/Plano de saúde
            </SimpleText>
            <SimpleText fontSize="xs">
              {familiar.nome}
              <span className="color_60">{familiar.planoSaude}</span>
            </SimpleText>
            <div className="p-d-flex p-gap-1 p-mt-1">
              {familiar.cids?.slice(0, 3).map((cid: any) => (
                <div key={cid.id} className="tag">
                  <Tooltip target={`.${cid.codigo + cid.id}`} />
                  <SimpleText
                    className={`comorbidades-ativas tag ${cid.codigo + cid.id}`}
                    data-pr-tooltip={cid.nome}
                    data-pr-position="top"
                  >
                    {cid.codigo}
                  </SimpleText>
                </div>
              ))}
              {familiar.cids && familiar.cids?.length > 3 && (
                <div
                  className="cursor-pointer"
                  onClick={() => listaCidsModal.open({ state: familiar.cids })}
                >
                  <SimpleText
                    fontColor="primary"
                    medium
                    className="cursor-pointer"
                  >
                    +{familiar.cids?.length - 3}
                  </SimpleText>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="p-col-6 p-d-flex p-flex-column p-gap-1 p-jc-start">
          <SimpleText fontColor="color_60" fontSize="xs">
            Sexo
          </SimpleText>
          <SimpleText fontSize="xs">
            {capitalizeFirstLetter(familiar.sexo?.descricao || '')}
          </SimpleText>
        </div>
        <div className="p-col-6 p-d-flex p-flex-column p-gap-1 p-jc-start">
          <SimpleText fontColor="color_60" fontSize="xs">
            Data de nascimento
          </SimpleText>
          <SimpleText fontSize="xs">
            {familiar.dataNascimento
              ? dayjs(familiar.dataNascimento).format('DD/MM/YYYY')
              : '-'}{' '}
          </SimpleText>
        </div>
        <div className="p-col-6 p-d-flex p-flex-column p-gap-1 p-jc-start">
          <SimpleText fontColor="color_60" fontSize="xs">
            Data de óbito
          </SimpleText>
          <SimpleText fontSize="xs">
            {familiar.dataObito
              ? dayjs(familiar.dataObito).format('DD/MM/YYYY')
              : ''}{' '}
          </SimpleText>
        </div>
        <div className="p-col-6 p-d-flex p-flex-column p-gap-1 p-jc-start">
          <SimpleText fontColor="color_60" fontSize="xs">
            Parentesco
          </SimpleText>
          <SimpleText fontSize="xs">
            {familiar.descricaoVinculoFamiliar || ''}
          </SimpleText>
        </div>
        <div className="p-col-6 p-d-flex p-flex-column p-gap-1 p-jc-start p-col-6 p-md-3">
          <SimpleText fontColor="color_60" fontSize="xs">
            Filiação
          </SimpleText>
          <SimpleText fontSize="xs">{familiar.filiacao ? capitalizeFirstLetter(familiar.filiacao) : ''}</SimpleText>
        </div>
        <div className="p-col-6 p-d-flex p-flex-column p-gap-1 p-jc-start p-col-6 p-md-3">
          <SimpleText fontColor="color_60" fontSize="xs">
            Gestação
          </SimpleText>
          <SimpleText fontSize="xs">
            {familiar.gestacao ? 'Sim' : 'Não'}
          </SimpleText>
        </div>
        <div className="p-col-12 p-d-flex p-flex-column p-gap-1 p-jc-start p-col-12 p-md-6">
          <SimpleText fontColor="color_60" fontSize="xs">
            Observações
          </SimpleText>
          <SimpleText fontSize="xs">{familiar.observacoes || ''}</SimpleText>
        </div>
        {familiar?.dataAtualizacao && (
          <div className="p-col-12 row p-p-2 bg-neutral-color">
            <SimpleText>
              Alterado por{' '}
              <b className="color_60">
                {familiar.usuarioAtualizacao?.nome || '-'}
              </b>{' '}
              em{' '}
              {dayjs(familiar?.dataAtualizacao).format('DD/MM/YYYY [às] HH:mm')}
            </SimpleText>
          </div>
        )}
        <div className="p-col-12 row p-p-2 bg-neutral-color">
          <SimpleText>
            Incluido por{' '}
            <b className="color_60">{familiar?.usuarioInclusao?.nome || '-'}</b>{' '}
            em {dayjs(familiar?.dataInclusao).format('DD/MM/YYYY [às] HH:mm')}
          </SimpleText>
        </div>
      </div>
    </SearchListMobileRow>
  );
};

export default MobileRow;
