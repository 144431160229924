import { useState } from 'react';

import { useNavigate } from 'react-router';

import dayjs from 'dayjs';
import ProfissionalAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import { IndicadoresAgenda } from '../_components/IndicadoresAgenda';
import { IndicadoresFiltered } from '../_components/IndicadoresFiltered';
import { Button } from 'src/components/_UI';
import { SelectButtonWithAction } from 'src/components/_UI/SelectButtonWithAction/SelectButtonWithAction';
import { InputText } from 'src/components/Basics/Input';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { useDashboard } from '../_hooks/useDashboard';

export const DashboardFilter = ({}) => {
  const { consultorios, filters, setFilters } = useDashboard();
  const { tipoUsuario = '' } = useAppSelector(state => state.user);

  const navigate = useNavigate();

  const fetchApi = async ({ query = '' }: any) => {
    try {
      const response = await ProfissionalAPI.loadProfissionaisSaudeBySearch(
        { nome: query },
        {
          throwError: true,
        },
      );
      return response;
    } catch {
      return [];
    }
  };

  const handleSetValue = (value: any) => {
    if (
      !!value?.periodo &&
      (!value?.periodo?.dataInicial || !value?.periodo?.dataFinal)
    ) {
      return;
    }

    setFilters({
      ...filters,
      ...value,
    } as any);
  };

  const hasAdictionalFilters = ['MASTER', 'ADMINISTRADOR'].includes(
    tipoUsuario,
  );

  return (
    <div className="row content-row-outline p-col-12 p-mt-4 p-flex-wrap">
      <SelectButtonWithAction
        type="dateRange"
        className="p-col-12 p-md-6 p-px-0"
        icon="fas fa-calendar"
        value={filters.periodo}
        onChange={({ value }) => {
          handleSetValue({
            periodo: {
              dataInicial: value?.dataInicial,
              dataFinal: value?.dataFinal,
            },
          });
        }}
        onActionChange={value => {
          value?.length > 1 &&
            dayjs(value[0]).isValid() &&
            dayjs(value[1]).isValid() &&
            handleSetValue({
              periodo: {
                dataInicial: dayjs(value[0]).format('DD-MM-YYYY'),
                dataFinal: dayjs(value[1]).format('DD-MM-YYYY'),
              },
            });
        }}
        options={[
          {
            label: 'Hoje',
            value: {
              dataInicial: dayjs().format('DD-MM-YYYY'),
              dataFinal: dayjs().format('DD-MM-YYYY'),
            },
          },
          {
            label: 'Três dias',
            value: {
              dataInicial: dayjs().subtract(3, 'day').format('DD-MM-YYYY'),
              dataFinal: dayjs().format('DD-MM-YYYY'),
            },
          },
          {
            label: 'Semana',
            value: {
              dataInicial: dayjs().subtract(1, 'week').format('DD-MM-YYYY'),
              dataFinal: dayjs().format('DD-MM-YYYY'),
            },
          },
          {
            label: 'Mês',
            value: {
              dataInicial: dayjs().subtract(1, 'month').format('DD-MM-YYYY'),
              dataFinal: dayjs().format('DD-MM-YYYY'),
            },
          },
        ]}
        settings={{
          date: {
            maxDate: new Date(),
          },
        }}
      />

      {hasAdictionalFilters && (
        <SelectButtonWithAction
          type="search"
          className="p-col-6 p-md-3 p-px-0"
          icon="fas fa-hospital"
          value={'TODOS'}
          options={[
            {
              label: 'Todos',
              value: 'TODOS',
            },
          ]}
          onActionChange={item => handleSetValue({ idConsultorio: item.id })}
          onChange={() => handleSetValue({ idConsultorio: undefined })}
          settings={{
            search: {
              list:
                consultorios?.map(({ nome, nomeResumido, id }: any) => ({
                  label: nome,
                  value: {
                    id,
                    name: nomeResumido,
                  },
                })) || [],
            },
          }}
        />
      )}

      {(hasAdictionalFilters || tipoUsuario === 'SECRETARIO') && (
        <SelectButtonWithAction
          type="custom"
          className="p-col p-px-0"
          icon="fas fa-stethoscope"
          value={'TODOS'}
          options={[
            {
              label: 'Todos',
              value: 'TODOS',
            },
          ]}
          onChange={() => handleSetValue({ idProfissional: undefined })}
          settings={{
            custom: ({ overlayRef, onValueChange }) => (
              <SearchList.Root fetchApi={fetchApi}>
                <DashboardFilterProfissional
                  onSelect={(item: any) => [
                    overlayRef.current?.hide(),
                    onValueChange({ label: item.nome, value: item.id }),
                    handleSetValue({ idProfissional: item.id }),
                  ]}
                />
              </SearchList.Root>
            ),
          }}
        />
      )}

      <div className="p-col-12 p-md-6">
        <IndicadoresFiltered className="p-w-100" />
      </div>

      <div className="p-col-12 p-md-6">
        <div className="p-d-flex p-jc-between p-mb-1 p-ai-center">
          <SimpleText fontSize="xs" fontColor="color_40" medium>
            Atendimentos
          </SimpleText>
          {['PROFISSIONAL_SAUDE', 'SECRETARIO'].includes(tipoUsuario) && (
            <Button
              btnType="ghost"
              label="Ver agenda"
              onClick={() => navigate('/agenda')}
            />
          )}
        </div>
        <IndicadoresAgenda className="p-w-100" />
      </div>
    </div>
  );
};

const DashboardFilterProfissional = ({
  onSelect,
}: {
  onSelect(item: any): void;
}) => {
  const [value, setValue] = useState('');
  const { setQuery } = useSearchList();

  const handleFilter = useThrottle((value: string) => setQuery(value), 1000);

  return (
    <div className="p-d-flex p-flex-column">
      <InputText
        className="p-col-12 input-search p-px-0"
        icon="fas fa-search"
        placeholder="Buscar"
        onChange={e => {
          setValue(e.target.value);
          handleFilter(e.target.value);
        }}
        value={value}
        iconPos="left"
      />

      <SearchList.InfiniteScroll
        className="p-d-flex p-flex-column p-gap-1"
        height={200}
        renderRow={(item: any) => (
          <div
            className="row content-row hover p-p-2"
            onClick={() => onSelect(item)}
          >
            {item.nome}
          </div>
        )}
      />
    </div>
  );
};
