import React from 'react';

import dayjs from 'dayjs';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { cn } from 'src/utils/cn';
import { capitalizeFirstLetter, checkAuth, enumToText } from 'src/utils/utils';

import { ListaCids } from './components/PanelHeader';
import { Checkbox } from 'src/components/_UI';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';
import Tooltip from 'src/components/Tooltip/Tooltip';

interface MobileRowProps {
  item: IAgravoNotificacao;
  selectedAgravo: IAgravoNotificacao[];
  setSelectedAgravo: (selectedAgravo: any) => void;
  onChangeStatus: () => void;
  loadingStatus: boolean;
}

const MobileRow = ({
  item,
  selectedAgravo,
  setSelectedAgravo,
  onChangeStatus,
  loadingStatus,
}: MobileRowProps) => {
  const listaCidsModal = useDisclosure({ opened: false });
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);

  const canChangeStatus = checkAuth(
    authorities,
    'ADM_MONITORAMENTO_NOTIFICACAO_ALTERAR',
  );

  return (
    <SearchListMobileRow options={[]}>
      <div className="p-grid p-ai-start p-jc-start">
        <Checkbox
          className="p-col-12 p-p-0"
          checked={selectedAgravo?.includes(item)}
          onChange={e => {
            if (e.checked) {
              setSelectedAgravo(
                selectedAgravo.length > 0 ? [...selectedAgravo, item] : [item],
              );
            } else {
              setSelectedAgravo(selectedAgravo?.filter((i: any) => i !== item));
            }
          }}
        />
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <div className="p-d-flex p-flex-column">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Agravo
            </SimpleText>
            <SimpleText fontSize="xs" fontColor="color_16">
              {item?.agravo?.nome || ''}
            </SimpleText>
          </div>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontSize="xxs" fontColor="color_60">
            CID
          </SimpleText>
          <div className="p-d-flex p-gap-1">
            {item.agravo?.cids?.slice(0, 3).map((cid: any) => (
              <div key={cid.id}>
                <Tooltip target={`.${cid.codigoCid + cid.id}`} />
                <SimpleText
                  className={`comorbidades-ativas ${cid.codigoCid + cid.id}`}
                  data-pr-tooltip={cid.nomeCid}
                  data-pr-position="top"
                >
                  {cid.codigoCid}
                </SimpleText>
              </div>
            ))}
            {item.agravo?.cids && item.agravo?.cids?.length > 3 && (
              <div
                className="cursor-pointer"
                onClick={() =>
                  listaCidsModal.open({ state: item.agravo?.cids })
                }
              >
                <SimpleText
                  fontColor="primary"
                  medium
                  className="cursor-pointer"
                >
                  +{item.agravo?.cids?.length - 3}
                </SimpleText>
              </div>
            )}
          </div>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Autoridade de saúde
          </SimpleText>
          <SimpleText fontColor="color_40" fontSize="xs">
            {item.agravo?.autoridadeSaude
              ? enumToText(item.agravo?.autoridadeSaude)
              : ''}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontSize="xxs" fontColor="color_60">
            Status
          </SimpleText>

          <Dropdown
            className="p-w-100"
            value={item.notificado ? 'NOTIFICADO' : 'NAO_NOTIFICADO'}
            options={[
              { label: 'Notificado', value: 'NOTIFICADO' },
              { label: 'Não notificado', value: 'NAO_NOTIFICADO' },
            ]}
            onChange={() => onChangeStatus()}
            disabled={loadingStatus || !canChangeStatus}
            filter={false}
            type={item.notificado ? 'outline' : 'danger'}
          />
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontSize="xxs" fontColor="color_60">
            Diagnostico
          </SimpleText>

          <SimpleText fontSize="xs" fontColor="color_16">
            {capitalizeFirstLetter(item?.diagnostico || '')}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontSize="xxs" fontColor="color_60">
            Data ocorrência
          </SimpleText>

          <SimpleText fontSize="xs" fontColor="color_16">
            {item.dataOcorrencia
              ? dayjs(item.dataOcorrencia).format('DD/MM/YYYY HH:mm')
              : ''}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Periodicidade
          </SimpleText>
          <SimpleText fontColor="color_40" fontSize="xs">
            {item.agravo?.periodicidade
              ? capitalizeFirstLetter(item.agravo?.periodicidade)
              : ''}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontSize="xxs" fontColor="color_60">
            Paciente
          </SimpleText>
          <SimpleText
            fontSize="xs"
            fontColor="color_16"
            className="p-js-center"
          >
            {item.atendimento?.nomePaciente || ''}
          </SimpleText>
        </div>

        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-12 p-md-6">
          <SimpleText fontSize="xxs" fontColor="color_60">
            CPF
          </SimpleText>
          <SimpleText
            fontSize="xs"
            fontColor="color_16"
            className="p-js-center"
          >
            {item.atendimento?.cpf || ''}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-12 p-md-6">
          <SimpleText fontSize="xxs" fontColor="color_60">
            CNS
          </SimpleText>
          <SimpleText
            fontSize="xs"
            fontColor="color_16"
            className="p-js-center"
          >
            {item.atendimento?.cns || ''}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-12 p-md-6">
          <SimpleText fontSize="xxs" fontColor="color_60">
            Médico
          </SimpleText>
          <SimpleText
            fontSize="xs"
            fontColor="color_16"
            className="p-js-center"
          >
            {item.atendimento.nomeMedico || ''}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-12 p-md-6">
          <SimpleText fontSize="xxs" fontColor="color_60">
            CRM
          </SimpleText>
          <SimpleText
            fontSize="xs"
            fontColor="color_16"
            className="p-js-center"
          >
            {item.atendimento.cfmUf || ''}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-12 p-md-6">
          <SimpleText fontSize="xxs" fontColor="color_60">
            CNES
          </SimpleText>
          <SimpleText
            fontSize="xs"
            fontColor="color_16"
            className="p-js-center"
          >
            {item.atendimento.cnes || ''}
          </SimpleText>
        </div>
      </div>

      {listaCidsModal.isOpen ? <ListaCids {...listaCidsModal} /> : <></>}
    </SearchListMobileRow>
  );
};

export default MobileRow;
