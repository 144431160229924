import { useCallback, useEffect, useState } from 'react';

import { DashboardAPI } from 'src/APIs/AdminAPI/DashboardAPI/DashboardAPI';
import { AssinaturaAPI } from 'src/APIs/AgendaAPI/AssinaturaAPI/AssinaturaAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { cn } from 'src/utils/cn';
import { checkAuth } from 'src/utils/utils';

import { Card } from './_card';

type Indicadores = {
  quantidadeProntuariosNaoAssinados: number | null;
  tissPendentes: number | null;
  tissRealizadas: number | null;
  examesSolicitados: number | null;
  atendimentos: IDashboardAtendimentosResponse | null;
};

export const IndicadoresTotais = ({ className }: { className?: string }) => {
  const { user, consultorios } = useAppSelector(state => state);

  const canSign = true;

  const [isLoading, setIsLoading] = useState(true);
  const [indicadores, setIndicadores] = useState<Indicadores>({
    quantidadeProntuariosNaoAssinados: null,
    tissPendentes: null,
    tissRealizadas: null,
    examesSolicitados: null,
    atendimentos: null,
  });

  const fetchTissPendentes = useCallback(async () => {
    if (!['MASTER', 'ADMINISTRADOR'].includes(user.tipoUsuario)) return null;

    try {
      const response = await DashboardAPI.loadIndicadorTissPendentes(
        {
          ...(consultorios?.ativo?.id && {
            idConsultorio: consultorios?.ativo?.id,
          }),
        },
        { throwError: true },
      );

      return response;
    } catch {
      return null;
    }
  }, [consultorios?.ativo?.id, user.tipoUsuario]);

  const fetchTissRealizadas = useCallback(async () => {
    if (!['MASTER', 'ADMINISTRADOR'].includes(user.tipoUsuario)) return null;

    try {
      const response = await DashboardAPI.loadIndicadorTissRealizadas(
        {
          ...(consultorios?.ativo?.id && {
            idConsultorio: consultorios?.ativo?.id,
          }),
        },
        { throwError: true },
      );

      return response;
    } catch {
      return null;
    }
  }, [consultorios?.ativo?.id, user.tipoUsuario]);

  const fetchExamesSolicitados = useCallback(async () => {
    try {
      const response = await DashboardAPI.loadIndicadorExamesSolicitados(
        {
          ...(consultorios?.ativo?.id && {
            idConsultorio: consultorios?.ativo?.id,
          }),
        },
        { throwError: true },
      );

      return response;
    } catch {
      return null;
    }
  }, [consultorios?.ativo?.id]);

  const fetchProntuariosNaoAssinados = useCallback(async () => {
    if (
      user.tipoUsuario !== 'PROFISSIONAL_SAUDE' &&
      user.tipoProfissionalSaude !== 'MEDICO'
    )
      return null;

    try {
      const response = await AssinaturaAPI.getProntuariosNaoAssinados(
        {},
        { throwError: true },
      );

      return response.data.totalCount;
    } catch {
      return null;
    }
  }, [user.tipoProfissionalSaude, user.tipoUsuario]);

  const fetchAtendimentos = useCallback(async () => {
    if (!['SECRETARIO'].includes(user.tipoUsuario)) return null;

    try {
      const response = await DashboardAPI.loadIndicadorAtendimentos(
        {
          ...(consultorios?.ativo?.id && {
            idConsultorio: consultorios?.ativo?.id,
          }),
        },
        { throwError: true },
      );

      return response;
    } catch {
      return null;
    }
  }, [consultorios?.ativo?.id, user.tipoUsuario]);

  useEffect(() => {
    Promise.allSettled([
      fetchProntuariosNaoAssinados(),
      fetchTissPendentes(),
      fetchTissRealizadas(),
      fetchExamesSolicitados(),
      fetchAtendimentos(),
    ]).then(
      ([
        prontuariosNaoAssinados,
        tissPendentes,
        tissRealizadas,
        examesSolicitados,
        atendimentos,
      ]) => {
        setIsLoading(false);
        setIndicadores({
          quantidadeProntuariosNaoAssinados:
            prontuariosNaoAssinados.status === 'fulfilled'
              ? prontuariosNaoAssinados.value
              : null,
          tissPendentes:
            tissPendentes.status === 'fulfilled' ? tissPendentes.value : null,
          tissRealizadas:
            tissRealizadas.status === 'fulfilled' ? tissRealizadas.value : null,
          examesSolicitados:
            examesSolicitados.status === 'fulfilled'
              ? examesSolicitados.value
              : null,
          atendimentos:
            atendimentos.status === 'fulfilled' ? atendimentos.value : null,
        });
      },
    );
  }, [
    fetchAtendimentos,
    fetchExamesSolicitados,
    fetchProntuariosNaoAssinados,
    fetchTissPendentes,
    fetchTissRealizadas,
  ]);

  return (
    <div
      className={cn('row content-row white p-p-3 p-gap-2', className)}
      style={{
        overflowX: 'auto',
        border: '1px solid var(--neutral-color-grayscale-88)',
      }}
    >
      <Card
        quantidade={indicadores?.atendimentos?.atendidos || null}
        title="Atendimentos"
        isLoading={isLoading}
      />
      {canSign && (
        <Card
          quantidade={indicadores.quantidadeProntuariosNaoAssinados}
          title="Prontuários pendentes de assinatura"
          label="Assinar"
          isLoading={isLoading}
          {...(checkAuth(user.authorities, 'PRO_ASSINATURA_ELETRONICA') && {
            route: '/pacientes/assinatura-prontuarios',
          })}
        />
      )}
      <Card
        quantidade={indicadores.examesSolicitados}
        title="Exames solicitados"
        isLoading={isLoading}
      />
      {canSign && (
        <Card
          quantidade={indicadores.tissPendentes}
          route="/monitoramento/transacoesTiss"
          state={{
            tab: 1,
          }}
          title="Transações TISS pendentes"
          isLoading={isLoading}
        />
      )}
      {user.tipoUsuario !== 'SECRETARIO' && (
        <Card
          quantidade={indicadores.tissRealizadas}
          route="/monitoramento/transacoesTiss"
          title="Transações TISS realizadas"
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
