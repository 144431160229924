import { useCallback, useEffect, useState } from 'react';

import { DashboardAPI } from 'src/APIs/AdminAPI/DashboardAPI/DashboardAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { cn } from 'src/utils/cn';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { useDashboard } from '../_hooks/useDashboard';

import './style.scss';

export const IndicadoresAgenda = ({ className }: { className?: string }) => {
  const { ativo } = useAppSelector(state => state.consultorios);

  const [indicador, setIndicador] = useState<IDashboardAtendimentosResponse>({
    agendados: 0,
    atendidos: 0,
    cancelados: 0,
    falta: 0,
    pendentes: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  const { filters } = useDashboard();

  const fetchAtendimentosData = useCallback(async () => {
    setIsLoading(true);

    try {
      const res = await DashboardAPI.loadIndicadorAtendimentos(
        {
          dataInicial: filters.periodo?.dataInicial,
          dataFinal: filters.periodo?.dataFinal,
          ...(filters.idProfissional && {
            idProfissional: filters.idProfissional,
          }),
          ...(ativo?.id && {
            idConsultorio: ativo?.id,
          }),
          ...(filters?.idConsultorio && {
            idConsultorio: filters?.idConsultorio,
          }),
        },
        {
          throwError: true,
        },
      );

      setIndicador(res);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }, [
    filters.idProfissional,
    filters.periodo?.dataFinal,
    filters.periodo?.dataInicial,
  ]);

  useEffect(() => {
    fetchAtendimentosData();
  }, [fetchAtendimentosData]);

  return (
    <div
      className={cn(
        'IndicadoresAgenda row content-row white p-py-2',
        className,
      )}
      style={{
        border: '1px solid var(--neutral-color-grayscale-88)',
        height: '80px',
      }}
    >
      <div className="relative p-col p-d-flex p-flex-wrap p-gap-2">
        <Skeleton loading={isLoading} height="20px" width="80%">
          <SimpleText className="p-w-100" fontSize="sm" medium>
            {indicador.agendados}
          </SimpleText>
        </Skeleton>
        <SimpleText fontColor="color_40" fontSize="xxs" medium>
          Agendados
        </SimpleText>
      </div>
      <div className="relative p-col p-d-flex p-flex-wrap p-gap-2">
        <Skeleton loading={isLoading} height="20px" width="80%">
          <SimpleText className="p-w-100" fontSize="sm" medium>
            {indicador.atendidos}
          </SimpleText>
        </Skeleton>

        <SimpleText fontColor="color_40" fontSize="xxs" medium>
          Atendidos
        </SimpleText>
      </div>
      <div className="relative p-col p-d-flex p-flex-wrap p-gap-2">
        <Skeleton loading={isLoading} height="20px" width="80%">
          <SimpleText className="p-w-100" fontSize="sm" medium>
            {indicador.pendentes}
          </SimpleText>
        </Skeleton>

        <SimpleText fontColor="color_40" fontSize="xxs" medium>
          Pendentes
        </SimpleText>
      </div>
      <div className="relative p-col p-d-flex p-flex-wrap p-gap-2">
        <Skeleton loading={isLoading} height="20px" width="80%">
          <SimpleText className="p-w-100" fontSize="sm" medium>
            {indicador.cancelados}
          </SimpleText>
        </Skeleton>

        <SimpleText fontColor="color_40" fontSize="xxs" medium>
          Cancelados
        </SimpleText>
      </div>
      <div className="relative p-col p-d-flex p-flex-wrap p-gap-2">
        <Skeleton loading={isLoading} height="20px" width="80%">
          <SimpleText className="p-w-100" fontSize="sm" medium>
            {indicador.falta}
          </SimpleText>
        </Skeleton>

        <SimpleText fontColor="color_40" fontSize="xxs" medium>
          Faltas
        </SimpleText>
      </div>
    </div>
  );
};
